import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import env from '../../assets/env.js'

const DownloadModal = ({ show, handleClose }) => {
  const { t,i18n } = useTranslation(); 
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const formatDate = (date) => {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };

  const [errorMessages, setErrorMessages] = useState({
    date_from: '',
    date_to:''
  });

  useEffect(() => {
    if (show) {
      setDateRange([null, null]);
    }
  }, [show]);

  const handleDateChange = (update) => {
    setDateRange(update);
  };

  const onDownload = () => {
    const handleDownloadWithDate = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const url = new URL(env.API_URL + `/dashboard/hotspot-users/export`);
        url.searchParams.append('date_from', formatDate(startDate));
        url.searchParams.append('date_to', formatDate(endDate));
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  
            'Accept-Language': i18n.language,
            Authorization: `Bearer ${token}`,
         },
        });
  
        if (response.ok) {
          setDownloadSuccess(true);
          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'hotspot_users.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(downloadUrl);
        } else {
          const error = await response.json();
          const parsedError = JSON.parse(error.error.message);
          setErrorMessages({
            date_from:parsedError.date_from ? parsedError.date_from[0] : '',
            date_to:parsedError.date_to ? parsedError.date_to[0] : '',
          })
          // console.log(parsedError.date_from[0]);
          throw new Error(`Failed to download data: ${response.status}`);
        }
      } catch (error) {
        console.error('Error downloading data:', error);
      }
    };
  
    handleDownloadWithDate();
  };
  
  const placeholderText = startDate && endDate 
    ? `${formatDate(startDate)} - ${formatDate(endDate)}` 
    : "From |  To";

  const today = new Date();
  const maxSelectableDate = new Date(startDate?.getTime() + 30 * 24 * 60 * 60 * 1000);

  return (
    <Modal
      show={show}
      onHide={() =>{
        setDownloadSuccess(false);
        handleClose();
      }}
      aria-labelledby="modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      {downloadSuccess ? (
          <Modal.Body className='p-5'>
            <p className='text-success'><span id='change-color' className='fw-bold'>{t('Data will now start downloading.')} </span><span id="change-color">{t('This may take a minute.')}</span></p>
          </Modal.Body>
        ) : (
      <Modal.Body className='p-5'>
        <Row>
          <Col sm={12}>
           <p id='change-color'><span className='fw-bold'>{t('You are about to download personal data.')}</span> {t('Please behave according to GDPR regulations.')}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='mt-2' >            
            <div className="text-start">
            <Form.Label id='change-color' className=''>{t('Download data for period')}</Form.Label>
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                minDate={new Date('01/01/1970')}
                maxDate={endDate || today > maxSelectableDate ? maxSelectableDate : today}
                placeholderText={placeholderText}
                className="form-control mx-5 text-center"
              />
          </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='text-center'>
          {errorMessages.date_from && <div className="text-danger">{errorMessages.date_from}</div>}
        {errorMessages.date_to && <div className="text-danger">{errorMessages.date_to}</div>}

          </Col>
        </Row>
        <Row className='mt-4'>
          <Col sm={12} className='d-flex justify-content-center'>
          <Button variant="btn btn-primary fs-4 px-5" id='login-button' className='rounded-0' onClick={onDownload}>
            {t('Download')}
          </Button>
            <Button id='change-color' variant="btn px-5 mx-3" className='rounded-0 border-0 text-decoration-underline' onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </Col>
        </Row>

      </Modal.Body>
        )}
    </Modal>
  );
};

export default DownloadModal;
