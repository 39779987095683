import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import busSettings from '../../services/busServices';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media screen and (min-width: 767px) {
    height: 50vh;
  }
`;

const Bus = () => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  // Fetch content from the backend
  const fetchContent = async (language) => {
    setLoading(true);
    try {
      const response = await busSettings.get(`bus_text_${language}`);
      setContent(response.data.description.html_content); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching content:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch and setup language change listener
  useEffect(() => {
    fetchContent(i18n.language);

    const handleLanguageChange = (lng) => {
      fetchContent(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  // Handle content changes and send to the backend
  const handleChange = async (value) => {
    setContent(value);
    const data = {
      value: "1",
      description: {
        html_content: value
      }
    };
    try {
      await busSettings.publish(`bus_text_${i18n.language}`, data);
    } catch (error) {
      console.error('Error publishing content:', error);
    }
  };

  return (
    <Container>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ReactQuill value={content} onChange={handleChange} />
      )}
    </Container>
  );
};

export default Bus;

