import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomeScreen.css';
import backgroundImage from '../../assets/images/background-signin.png';
import logo_tav from '../../assets/images/logo-tav.png';
import wifi_ic from '../../assets/icons/wifi-ic.png';
import { Button } from 'react-bootstrap';


const WelcomeScreen = () => {
    const navigate = useNavigate();

    const handleEnterNow = (event) => {
      event.preventDefault();
      navigate(`/en/login`);
    };
    
  return (
    <div className="welcome-screen">
      <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="content">
        <div className="center-logo">
          <img src={logo_tav} alt="logo-tav" />
        </div>
        <div className="text-bottom-left">
          <span className="text-bottom-left-big">WELCOME TO<br></br> SKOPJE AIRPORT</span><br></br>
          <span className='text-bottom-left-small'>WI-FI ADMIN PANEL <img src={wifi_ic} alt="Icon" /></span><br></br>
          <Button className='mt-3' onClick={handleEnterNow} size='lg' id='button-enter'>ENTER NOW</Button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
