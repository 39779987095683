import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './MyProfileModal.css';
import marker_ic from '../../assets/icons/marker-ic.png'
import { Link } from 'react-router-dom';
import env from '../../assets/env.js'


const MyProfileModal = ({ show, handleClose }) => {
  const [profile, setProfile] = useState({});
  const [initialProfile, setInitialProfile] = useState({});
  const { t,i18n } = useTranslation(); 
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false); 
  const [passwordError, setPasswordError] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [errorMessages, setErrorMessages] = useState({
    name: '',
    surname: '',
  });

  const [errorMessagesPassword, setErrorMessagesPassword] = useState({
    old_password: '',
    new_password: '',
    new_password_confirmation:'',
  });

  const isPasswordValid = (password) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/.test(password);
  };
  
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (!isPasswordValid(event.target.value)) {
      setPasswordError(t("Your password does not meet our security standards. (At least one uppercase letter, one lowercase letter, one number)"));
    } else {
      setPasswordError('');
    }
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value !== password) {
      setPasswordError('Password does not match!');
    } else {
      setPasswordError('');
    }
  };

const isPasswordMatch = password === confirmPassword && password !== '';


  const handleChangePasswordModal = () => {
    setShowChangePassword(true);
  };

  const handleChangePasswordAccept = () => {
    handleChangePassword()
  };
  const handleCancelChangePassword = () => {
    setShowChangePassword(false);
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(env.API_URL+'/dashboard/myprofile/change-password', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Language': i18n.language
        },
        body: JSON.stringify({
          old_password: profile.old_password,
          new_password: password,
          new_password_confirmation: confirmPassword
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        // console.log('Response:', data);
        setPassword("");
        setConfirmPassword("");
        setChangeSuccess(true);
      } else {
        const error = await response.json();
        if(error.error.message){
          setErrorMsg(error.error.message);
        }else{
        const parsedError = JSON.parse(error.error.message);
        setErrorMessagesPassword({
          old_password: parsedError.old_password ? parsedError.old_password[0] : '',
          new_password: parsedError.new_password ? parsedError.new_password[0] : '',
          new_password_confirmation: parsedError.new_password_confirmation ? parsedError.new_password_confirmation[0] : '',
        });
      }
        throw new Error('Failed to edit user');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };


  const handleEditMyProfile = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(env.API_URL+'/dashboard/myprofile/edit', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Language': i18n.language
        },
        body: JSON.stringify({
          name: profile.name,
          surname: profile.surname,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        setErrorMessages({ name: '', surname: ''});
        setEditSuccess(true);
      } else {
        const error = await response.json();
        const parsedError = JSON.parse(error.error.message);
        setErrorMessages({
          name: parsedError.name ? parsedError.name[0] : '',
          surname: parsedError.surname ? parsedError.surname[0] : '',
        });
        throw new Error('Failed to edit user');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };
  
  const fetchProfileData = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(env.API_URL+'/dashboard/myprofile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data } = response.data;
      // console.log(data);
      setProfile(data);
      setInitialProfile(data); // Store initial profile data
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchProfileData();
    }
  }, [show]);
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile({ ...profile, [name]: value });
  };

  const isProfileEdited = () => {
    return profile.name !== initialProfile.name ||
           profile.surname !== initialProfile.surname; // Extend this comparison for other fields as needed
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        setChangeSuccess(false);
        setEditSuccess(false);
        setShowChangePassword(false);
        setErrorMessages({ name: '', surname: ''});
        setErrorMsg("");
        setErrorMessagesPassword({
          old_password: '',
          new_password: '',
          new_password_confirmation:'',
        });
        setPassword("");
        setConfirmPassword("");
        handleClose()
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    <div>
    <Modal.Header closeButton>
      </Modal.Header>
    {!showChangePassword ? (
      <Modal.Body>
        {editSuccess ? (
              <p id='change-color' className='fw-bold'>{t('Profile updated successfully!')}</p>
            ) : (
        <>
        <h5 id='change-color'>{t('MY PROFILE')}</h5>
          <Form onSubmit={handleEditMyProfile} className="form-group">
            <Row className="mb-3">
              <Form.Group controlId="formName" as={Col} className="d-flex align-items-center">
                <Form.Label column sm={3} id='change-color'>{t('Name')}</Form.Label>
                <Col sm={9} id='change-color'>
                  <Form.Control
                    type="text"
                    name="name"
                    value={profile.name}
                    onChange={handleInputChange}
                    className="rounded-0"
                  />
                {errorMessages.name && <div className="text-danger">{errorMessages.name}</div>}
                </Col>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group controlId="formSurname" as={Col} className="d-flex align-items-center">
                <Form.Label id='change-color' column sm={3}>{t('Surname')}</Form.Label>
                <Col sm={9} id='change-color'>
                  <Form.Control
                    type="text"
                    name="surname"
                    value={profile.surname}
                    onChange={handleInputChange}
                    className="rounded-0"
                  />
                 {errorMessages.surname && <div className="text-danger">{errorMessages.surname}</div>}
                </Col>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group controlId="formEmail" as={Col} className="d-flex align-items-center">
                <Form.Label column sm={3} id='change-color'>{t('Email')}</Form.Label>
                <Col sm={9} id='change-color'>
                  <Form.Control
                    type="email"
                    name="email"
                    value={profile.email || ''}
                    onChange={handleInputChange}
                    className="rounded-0"
                    disabled
                  />
                </Col>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group controlId="formPassword" as={Col} className="d-flex align-items-center">
                <Form.Label column sm={3} id='change-color'>{t('Password')}</Form.Label>
                <Col sm={8} id='change-color'> 
                  <Form.Control
                    type="password"
                    name="password"
                    value={profile.password || '**********'}
                    onChange={handleInputChange}
                    disabled
                    className="rounded-0"
                  />
                </Col>
                <Col sm={10}>
                  <Link>
                    <img onClick={handleChangePasswordModal} src={marker_ic} alt='marker-ic' id='change-password-img'/>
                  </Link>
                  </Col>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="d-flex align-items-center">
                <Form.Label column sm={3} id='change-color'>{t('User Type')}</Form.Label>
                <Col sm={9} id='change-color'>
                  <Form.Control
                    type="text"
                    name="user-type"
                    value={profile.role?.name || ''}
                    onChange={handleInputChange}
                    className="rounded-0"
                    disabled
                  />
                </Col>
              </Form.Group>
            </Row>
          <Row className="mt-4">
            <Col sm={12} className='d-flex justify-content-center '>
              <Button onClick={handleEditMyProfile}  disabled={!isProfileEdited()} variant="btn btn-primary fs-3" id='login-button' type="submit" className='rounded-0'>
                {t('Save Changes')}
              </Button>
              <Button variant="outline-secondary" onClick={handleClose} className='rounded-0 border-0 text-decoration-underline mx-4'>
                {t('Cancel')}
              </Button>
            </Col>
          </Row>
        </Form>
        </>
      )}
      </Modal.Body>
        ) : (
      <div className='p-4'>
        {changeSuccess ? ( 
              <p id='change-color' className=''>Your password has successfully been changed.</p>
            ) : (
      <Modal.Body className='p-4'>
      <h3>{t('CHANGE PASSWORD')}</h3>
        <Form onSubmit={handleChangePassword} className="form-group">
          <Row className="mb-3">
            <Form.Group as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3}>{t('Current password')}</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="password"
                  name="old_password"
                  placeholder={t('Password*')}
                  value={profile.old_password || ''}
                  onChange={handleInputChange}
                  className="rounded-0"
                  required
                />
                {errorMessagesPassword.old_password && <div className="text-danger">{errorMessagesPassword.old_password}</div>}
              </Col>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3}>{t('New password')}</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="password"
                  placeholder={t('New password')+'*'}
                  name="new_password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="rounded-0"
                  required
                />
              {errorMessagesPassword.new_password && <div className="text-danger">{errorMessagesPassword.new_password}</div>}
              </Col>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3}>{t('Repeat new password')}</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="password"
                  placeholder={t('Repeat new password')+'*'}
                  name="new_password_confirmation"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className="rounded-0"
                  required
                />
              {errorMessagesPassword.new_password_confirmation && <div className="text-danger">{errorMessagesPassword.new_password_confirmation}</div>}
              </Col>
            </Form.Group>
            {errorMsg && <div className="text-danger">{errorMsg}</div>}
          </Row>
          {passwordError && (
            <p className="error-text text-danger">
              {passwordError}
            </p>
          )}
          <Row className="">   
            <Col sm={6} className='d-flex justify-content-center '>
              <Button disabled={!isPasswordMatch || !isPasswordValid(password) || !profile.old_password} onClick={handleChangePassword} variant="btn btn-primary fs-4" id='login-button' type="submit" className='rounded-0'>
                {t('Apply Changes')}
              </Button>
            </Col>
            <Col sm={6} className='d-flex justify-content-center'>
              <Button variant="outline-secondary" onClick={handleCancelChangePassword} className='rounded-0  border-0 text-decoration-underline '>
                {t('Back')}
              </Button>
            </Col>
          </Row>
        </Form>
        </Modal.Body>
            )}
      </div>
      )}
      </div>
    </Modal>
  );
};

export default MyProfileModal;
