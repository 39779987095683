/* eslint-disable no-unused-vars */
import React,{useState, useEffect} from 'react';
import { useNavigate,useLocation,Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './ChangePassword.css';
import logo_tav from '../../assets/images/logo-tav.png';
import { useTranslation } from 'react-i18next';
import env from '../../assets/env.js'


const ChangePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const [email, setEmail] = useState(location.state?.email);
    const [recoveryCode, setRecoveryCode] = useState(location.state?.code);
    const {  t,i18n } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const languageOptions = ['en', 'mk', 'al'];
    const language_text = ['PLEASE SELECT YOUR PREFERRED LANGUAGE', 'ВЕ МОЛИМЕ ИЗБЕРЕТЕ ГО ВАШИОТ ЈАЗИК', 'JU LUTEM ZGJIDHNI GJUHËN TUAJ E PREFERUAR'];
    const [passwordError, setPasswordError] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [isLanguageManuallySelected, setIsLanguageManuallySelected] = useState(false);
    const [changeSuccess, setChangeSuccess] = useState(false);

    const handleChangePassword = async (event) => {
      event.preventDefault();

      if (isPasswordMatch && isPasswordValid(password))  {
        try {
          const response = await fetch(env.API_URL+'/forgot-password/reset', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': languageOptions[currentIndex],
            },
            body: JSON.stringify({
              email: email,
              code: recoveryCode,
              new_password: password,
              new_password_confirmation: confirmPassword
            }),
          });
    
          if (response.status === 200) {
            setChangeSuccess(true);
            const timer = setTimeout(() => {
              navigate(`/${i18n.language}/login`, { state: { email: email } });
            }, 3000);
            } else {
            const error = await response.json();
            console.log(error.error.message);
          }
        } catch (error) {
          console.error('Error during code verification:', error);
        }
      }
    };
    
    const isPasswordValid = (password) => {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/.test(password);
    };
    
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
      if (!isPasswordValid(event.target.value)) {
        setPasswordError(t("Your password does not meet our security standards. At least 8 characters (one uppercase letter, one lowercase letter, one number)"));
      } else {
        setPasswordError('');
      }
    };

    const handleConfirmPasswordChange = (event) => {
      setConfirmPassword(event.target.value);
      if (event.target.value !== password) {
        setPasswordMatchError(t('Passwords do not match!'));
      } else {
        setPasswordMatchError('');
      }
    };
  

    const handleLanguageChange = (language) => {
      const newIndex = languageOptions.indexOf(language);
      if (newIndex !== -1) {
        setCurrentIndex(newIndex);
      }
      i18n.changeLanguage(language);
      setIsLanguageManuallySelected(true);
      navigate(`/${language}/forgot-password/code/change-password`);
    };
    const getButtonVariant = (languageIndex) => {
      return currentIndex === languageIndex ? "outline-primary fw-bold opacity-100" : "outline-primary";
    };

    useEffect(() => {
      if(passwordError){
        setPasswordError(t("Your password does not meet our security standards. At least 8 characters (one uppercase letter, one lowercase letter, one number)"));
      }else if(passwordMatchError){
        setPasswordMatchError(t('Passwords do not match!'));  
      }
    }, [t, passwordError, passwordMatchError]);
  
  

  const isPasswordMatch = password === confirmPassword && password !== '';
    
  return (
    <div className="login-screen">
      <div className="container-fluid">
        <div className="row">
            <div className='col-xl-12 text-center'>
                <div className="center-logo">
                  <Link to={`/${i18n.language}/login/`}>
                      <img src={logo_tav} alt="Your Logo" />
                  </Link>                  
                </div>
            </div>
        </div>
          <div id='row-parrent' className='row'>
          <div className="col-sm-6">
            <div className="language-chooser">
            <span className='mb-3' id='selection-text-normal'>{language_text[currentIndex]}</span>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(0)} // 0 for English
                onClick={() => handleLanguageChange('en')}
              >
                ENGLISH
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(1)} // 1 for Macedonian
                onClick={() => handleLanguageChange('mk')}
              >
                MACEDONIAN
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(2)} // 2 for Albanian
                onClick={() => handleLanguageChange('al')}
              >
                ALBANIAN
              </Button>
            </div>
          </div>
          <div className="col-sm-6 vertical-line">
          {changeSuccess ? ( 
              <h5 id='form-styling' className='mt-2'><span id='change-color'>{t('Your password has successfully been changed.')}</span></h5>
            ) : (
          <form id='form-styling' onSubmit={handleChangePassword}>
            <span id='login-txt' className='fs-5'>{t('CHANGE YOUR PASSWORD')}</span>
            <div className="form-group mb-3">
            <input
                placeholder={recoveryCode}
                type="text"
                className="form-control rounded-0"
                id="code"
                value={recoveryCode}
                disabled
                />
          </div>
          <div className='form-group'>
          <input
              type="hidden"
              className="form-control rounded-0"
              id="email"
              value={email}
              disabled
              />
          </div>
            <div className="form-group mb-3">
              <input
              placeholder={t('Password*')}
              type='password'
              className="form-control rounded-0"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              autoFocus
            />
          </div>
          <div className="form-group mb-3">
            <input
              placeholder={t('Repeat Password*')}
              type='password'
              className="form-control rounded-0"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              disabled={!isPasswordValid(password)}
            />
          </div>
          {passwordMatchError&& (
            <p className="error-text text-danger">
              {passwordMatchError}
            </p>
          )}
          {passwordError&& (
            <p className="error-text text-danger">
              {passwordError}
            </p>
          )}
          <button
            id='change-password-button'
            type="submit"
            className="btn btn-primary rounded-0 w-100 fs-4 mb-2"
            disabled={!isPasswordMatch || !isPasswordValid(password)}
            onClick={handleChangePassword}
          >
            {t('CHANGE PASSWORD')}
          </button>
          <Link className='w-100 opacity-50 ' id='change-color' to={`/${i18n.language}/login`}>{t('Back to LOG IN')}</Link>
            </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
