import React, { useState, useEffect } from 'react';
import {
    listRentACarOperators, 
    storeRentACarOperator, 
    editRentACarOperator, 
    deleteRentACarOperator,getRentACarOperatorDetails
} from '../../services/rentACarServices';
import OperatorEditPopup from './OperatorEditPopup';
import OperatorAddPopup from './OperatorAddPopup';
import {
    Container, Table, Button, AddButton, Input, TableRow, TableHead, AddRow, TableData
} from "../../components/manage-landing-components/Table";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import { useTranslation, withTranslation } from 'react-i18next';
import Notification from './Notification';
import LanguageTabsComponent from './ShowOnLandingPage';



const RentACarTable = () => {
    const [operators, setOperators] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [currentOperator, setCurrentOperator] = useState(null);
    const { t,i18n } = useTranslation();
    const [notification, setNotification] = useState({ show: false, message: '' });
    const [recentlyUpdated, setRecentlyUpdated] = useState(null); // Add this line


    useEffect(() => {
        fetchOperators();
    }, []);

    const fetchOperators = async () => {
        try {
            const data = await listRentACarOperators();
            setOperators(data.data);
        } catch (error) {
            console.error('Error fetching operators:', error);
        }
    };

    const openAddPopup = () => {
        setCurrentOperator(null);
        setShowPopup(true);
    };

    const openEditPopup = async (operator) => {
        try {
            const detailedData = await getRentACarOperatorDetails(operator.id);
            setCurrentOperator(detailedData.data);
            setShowPopup(true);
        } catch (error) {
            console.error('Error fetching detailed operator data:', error);
        }
    };

    const handleClosePopup = async () => {
       
        setRecentlyUpdated(currentOperator.id);
      
       
        await fetchOperators();
      

        setShowPopup(false);
 
        setTimeout(() => setRecentlyUpdated(null), 3000);
      };
    
    
    const handleSave = async (operatorData) => {
        try {
            const savedOperator = await storeRentACarOperator(operatorData); 
           
            
            setRecentlyUpdated(savedOperator.data.id); 
            fetchOperators(); 
            setShowPopup(false); 
    
           
            setTimeout(() => setRecentlyUpdated(null), 3000);
        } catch (error) {
            console.error('Error saving new operator:', error);
            
            setNotification({ show: true, message: 'error-adding-rent-a-car' });
        }
    };
    

    const handleDeleteOperator = async (id) => {
        await deleteRentACarOperator(id);
        fetchOperators();
        setNotification({ show: true, message: 'rent-a-car-deleted' });
    };

    return (
        <>
        <Container>

            <Table>
                <thead>
                    <TableRow>
                        <TableHead>{t("operator")}</TableHead>
                        <TableHead>{t("actions")}</TableHead>
                    </TableRow>
                </thead>
                <tbody>
                <AddRow><td colSpan="4"> <AddButton onClick={openAddPopup}>{t("add-new-operator")}</AddButton></td></AddRow>

                    {operators.map(operator => (
                        <TableRow key={operator.id}
                        style={{ backgroundColor: recentlyUpdated === operator.id ? 'rgba(20, 156, 26, 0.1)' : 'transparent' }}>
                            <TableData>{operator.name}</TableData>
                            <TableData>
                                <Button onClick={() => openEditPopup(operator)}><img src={editIcon} alt="Edit operator"></img></Button>
                                <Button onClick={() => handleDeleteOperator(operator.id)}><img src={deleteIcon} alt="Delete operator"></img></Button>
                            </TableData>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            {showPopup && currentOperator && (
    <OperatorEditPopup
        operator={currentOperator}
        onClose={handleClosePopup}
      
    />
)}

            {showPopup && !currentOperator && (
                <OperatorAddPopup
                    onClose={() => setShowPopup(false)}
                    onSave={handleSave}
                />
            )}

{notification.show && <Notification text={notification.message} />}

        </Container>
            <LanguageTabsComponent keyName="rent_a_car" />
            </>
    );
};

export default RentACarTable;
