/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState, useEffect, useCallback} from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

import logo_tav from '../assets/images/logo-tav.png';
import profile_ic from '../assets/icons/profile-ic.png';
import manage_cor_ic from '../assets/icons/manage-cor-ic.png';
import global_rules_ic from '../assets/icons/global-rules-ic.png';
import manage_landing_ic from '../assets/icons/manage-landing-ic.png';
import search_ic from '../assets/icons/search-ic.png';

import './IndexScreen.css';
import TableDash from '../components/tables/TableDash.js';
import DownloadModal from '../components/modals/DownloadModal.js';
import GlobalRulesModal from '../components/modals/GlobalRulesModal.js';
import MyProfileModal from '../components/modals/MyProfileModal.js';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';


const IndexScreen = () => {
  const [selectedTime, setSelectedTime] = useState('00:00');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('DESC'); 
  const { t, i18n } = useTranslation(); 
  const [showMyProfile, setShowMyProfile] = useState(false);
  const [showGlobalRules, setShowGlobalRules] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const userRole = localStorage.getItem('userRole');
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const debouncedSearch = useCallback(
    debounce((query) => {
      setCurrentPage(1);
      setDebouncedQuery(query);
    }, 500), 
    []
  );

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length >= 3) {
      debouncedSearch(query);
    }else if(query.length === 0 || query.length === 2) {
      debouncedSearch('');
    }
  };

  const handleSearchButtonClick = () => {
    setDebouncedQuery(searchQuery);
    setCurrentPage(1);
};

  useEffect(() => {
    document.title = 'Tav Index Dashboard';
  }, []);  
 const languageNames = {
  en: 'EN',
  mk: 'MK',
  al: 'AL',
};

const getCurrentLanguageName = () => {
  return languageNames[i18n.language] || 'Language';
};

  const [profileData, setProfileData] = useState({
    name: 'Name',
    surname: 'Surname',
    email: 'example@example.com',
    password: '', 
    userType: 'Marketing', 
  });

  const handleTimeChange = (increment) => {
    const [hours, minutes] = selectedTime.split(':').map(Number);
  
    let newTime;
    if (increment === 'up') {
      const totalMinutes = hours * 60 + minutes + 15;
      const newHours = Math.floor(totalMinutes / 60) % 24;
      const newMinutes = totalMinutes % 60;
      newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    } else if (increment === 'down') {
      const totalMinutes = (hours * 60 + minutes - 15 + 24 * 60) % (24 * 60);
      const newHours = Math.floor(totalMinutes / 60);
      const newMinutes = totalMinutes % 60;
      newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    }
  
    setSelectedTime(newTime);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
};

const handleSortOrderChange = (newOrder) => {
  setCurrentPage(1);
  setSortOrder(newOrder);
};

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('Submitting edited profile:', profileData);
    handleCloseShowMyProfile();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  
  const handleCloseShowMyProfile = () => setShowMyProfile(false);
  const handleShowMyProfile = () => setShowMyProfile(true);

  const handleShowMyProfileClick = (e) => {
    e.preventDefault();
    handleShowMyProfile(); 
  };

  const handleCloseGlobalRules = () => setShowGlobalRules(false);
  const handleShowGlobalRules = () => setShowGlobalRules(true);

  const handleCloseDownloadModal = () => setShowDownloadModal(false);
  const handleShowDownloadModal = () => setShowDownloadModal(true);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
      .then(() => {
        console.log(`Language changed to: ${lng}`);
      })
      .catch((error) => {
        console.error('Error while changing language:', error);
      });
  };
    return (
      <div  className="index-screen mt-0">
        <div className="container-fluid">
          <div className="row">
            <div id='language-selector' className='col-xl-4'>
            <Link className='fw-bold' to="#" id='smaller-header-links' onClick={handleShowMyProfileClick}>
              <img  className='img_signout' src={profile_ic} alt="My profile" />{t('My profile')}
            </Link>
            {userRole !== '2' && (
              <Link className='fw-bold' id='smaller-header-links' to={`/${i18n.language}/index/manage-corporate`}>
                <img className='img_signout ms-3' src={manage_cor_ic} alt="Manage corporate users" />{t('Manage corporate users')}
              </Link>
            )}    
        </div>
            <div className='col-xl-4 text-center'>
              <div className="center-logo">
                <Link to={`/${i18n.language}/index/`}>
                  <img src={logo_tav} alt="Your Logo" />
                </Link>               
              </div>
            </div>
            <div id='language-selector' className='col-xl-4 justify-content-end'>
            {userRole !== '2' && (
               <Link className='fw-bold' id='smaller-header-links' to={`/${i18n.language}/index/manage-landing-page`}>
               <img className='img_signout' src={manage_landing_ic} alt="Your Logo" />{t('Manage landing page info')}
             </Link>
            )}  
              <DropdownButton title={`${getCurrentLanguageName()}↓`} variant="btn fs-5 mx-2" id="dropdown-basic">
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('mk')}>Macedonian</Dropdown.Item>
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('al')}>Albanian</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className='row'>
          <div className='col-lg-3 d-flex align-items-center'>
              <input
                  id='change-color-search'
                  className='form-control w-75 rounded-0 flex-grow-1'
                  type="text"
                  placeholder={t('Search')}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  style={{ height: '2.2rem' }}
              />
              <Link onClick={handleSearchButtonClick} variant='primary' className=''>
                  <img className='' src={search_ic} alt='Search Icon' style={{ height: '2.2rem' }}/>
              </Link>
          </div>
            <div className='col-md-9 text-end'>
              <label id='change-color' className='fw-bold'>{t('Sort by')}:</label>
              <span id='change-color' className='me-4'>
              <Link
              id='change-color'
              className={`mx-1 ${sortOrder === 'DESC' ? '' : 'text-decoration-none'}`}
              onClick={() => handleSortOrderChange('DESC')}
            >
              {t('Most active')}
            </Link> |
            <Link
              id='change-color'
              className={`mx-1 ${sortOrder === 'ASC' ? '' : 'text-decoration-none'}`}
              onClick={() => handleSortOrderChange('ASC')}
            >
              {t('Least active')}
            </Link>           
            </span>
            {userRole !== '2' && (
              <span><Link className='fw-bold' id='change-color' onClick={handleShowGlobalRules}>{t('Global Rules')}<img className='img_signout me-4' src={global_rules_ic} alt="" /></Link></span>
            )}
              </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
            <TableDash 
              currentPage={currentPage}
              sortOrder={sortOrder}
              handleShowDownloadModal={handleShowDownloadModal}
              onPageChange={handlePageChange}
              searchQuery={debouncedQuery} 
              onSearchButtonClick={handleSearchButtonClick}
              />
            </div>
          </div>
      </div>
      <MyProfileModal
        show={showMyProfile}
        handleClose={handleCloseShowMyProfile}
        handleSubmit={handleSubmit}
        profileData={profileData}
        handleInputChange={handleInputChange}
      />
      <GlobalRulesModal
        show={showGlobalRules}
        handleClose={handleCloseGlobalRules}
        handleSubmit={handleSubmit}
        handleTimeChange={handleTimeChange}
      />
      <DownloadModal
        show={showDownloadModal}
        handleClose={handleCloseDownloadModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>
    );
};

export default IndexScreen;
