import axios from 'axios';
import {
    DASHBOARD_RENT_A_CAR_OPERATOR_LIST,
    DASHBOARD_RENT_A_CAR_OPERATOR_DETAILS,
    DASHBOARD_RENT_A_CAR_OPERATOR_STORE,
    DASHBOARD_RENT_A_CAR_OPERATOR_DELETE,
    DASHBOARD_RENT_A_CAR_OPERATOR_EDIT
} from './endpoints';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });
  
  const token = localStorage.getItem('accessToken');
  
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

export const listRentACarOperators = async () => {
    try {
        const response = await axios.get(DASHBOARD_RENT_A_CAR_OPERATOR_LIST, { config });
        return response.data;
    } catch (error) {
        console.error('Error fetching Rent-A-Car operators:', error);
        throw error;
    }
};

export const getRentACarOperatorDetails = async (id) => {
    try {
        const url = DASHBOARD_RENT_A_CAR_OPERATOR_DETAILS.replace('%(id)s', id);
        const response = await axios.get(url, { config });
        return response.data;
    } catch (error) {
        console.error('Error fetching Rent-A-Car operator details:', error);
        throw error;
    }
};

export const storeRentACarOperator = async (operatorData) => {
    try {
        const response = await axios.post(DASHBOARD_RENT_A_CAR_OPERATOR_STORE, operatorData, { config });
        return response.data;
    } catch (error) {
        console.error('Error storing Rent-A-Car operator:', error);
        throw error;
    }
};

export const deleteRentACarOperator = async (id) => {
    try {
        const url = DASHBOARD_RENT_A_CAR_OPERATOR_DELETE.replace('%(id)s', id);
        const response = await axios.delete(url, { config });
        return response.data;
    } catch (error) {
        console.error('Error deleting Rent-A-Car operator:', error);
        throw error;
    }
};

export const editRentACarOperator = async (id, operatorData) => {
    try {
        const url = DASHBOARD_RENT_A_CAR_OPERATOR_EDIT.replace('%(id)s', id);
        const formData = new FormData();
        for (const key in operatorData) {
            formData.append(key, operatorData[key]);
        }

        const response = await axios.post(url, formData, { config });
        return response.data;
    } catch (error) {
        console.error('Error editing Rent-A-Car operator:', error);
        throw error;
    }
};

