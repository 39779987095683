import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height:45vh;
  overflow:auto;
`;

export const Table = styled.table`
  width: 100%;

`;

export const Button = styled.div`
  // Button styles
`;

export const AddButton = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  background-color: #031F73;
  width: 100%;
  padding: 0.25rem;
  text-align: center;
  cursor:pointer;
`;

export const Input = styled.input`
background: none;
border: none;
outline: none;
box-shadow: none;
padding: 0;
margin: 0;
font-family: inherit;
font-size: inherit;
font-weigth:inherit;

  ::placeholder {
    font-weight: 300;
    color: #031F73;
  }
`;

export const TableRow = styled.tr`
  ${props => props.$isEditing && css`
    background-color: rgba(3, 31, 115, 0.1);
  `}
  ${props => props.$isRecentlyUpdated && css`
    background-color: rgba(20, 156, 26, 0.1);
    animation: fadeBackground 5s;
  `}

  @keyframes fadeBackground {
    from {
      background-color: rgba(20, 156, 26, 0.1);
    }
    to {
      background-color: transparent;
    }
  }
`;

export const TableHead = styled.th`
  color: #031F73 !important;
  font-weight: 700;
  padding: 0.5rem 0.75rem;
  &:last-child {
    text-align: center;
  }
`;

export const AddRow = styled.tr`
  background-color: #031F73;
`;

export const TableData = styled.td`
  font-weight: 300;
  color: #031F73;
  border-right: 0.5px solid black;
  border-bottom: 0.5px solid black;
  padding: 0.25rem;
  &:nth-child(2) {
    padding-left: 1rem;
  }
  &:nth-child(3) {
    padding-left: 1rem;
  }
  &:last-child {
    display: flex;
    cursor: pointer;
    border-right: unset;
    justify-content: center;
    align-items: center;
  }
`;
