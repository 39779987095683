import React, { useState, useEffect } from 'react';
import carParkServices from '../../services/carParkServices';
import checkmarkIcon from "../../assets/icons/accept.svg";
import cancelIcon from "../../assets/icons/cancel.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import {
    Container, Table, Button, AddButton, Input, TableRow, TableHead, AddRow, TableData
} from "../../components/manage-landing-components/Table";
import { useTranslation, withTranslation } from 'react-i18next';
import Notification from './Notification';

const CarHugAndFlyTable = () => {
    const { t,i18n } = useTranslation();
    const [subscriptions, setSubscriptions] = useState([]);
    const [adding, setAdding] = useState(false);
    const [newSubscription, setNewSubscription] = useState({ time: '',  price: '' });
    const [editingId, setEditingId] = useState(null);
    const [editSubscription, setEditSubscription] = useState({ time: '',  price: '' });
    const [recentlyUpdated, setRecentlyUpdated] = useState(null);
    const [tempIdForNewSubscription, setTempIdForNewSubscription] = useState(null);
    const [recentlyAdded, setRecentlyAdded] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '' });


    const handleEditStart = (subscription) => {
        setEditingId(subscription.id);
        setEditSubscription({ time: subscription.time,  price: subscription.price });
    };

    const handleAddSubscription = async () => {
     
        try {
            const subscriptionData = {
                ...newSubscription,
                time_quantity_type: "minutes"
            };
    
            const response = await carParkServices.hugAndFly.store(subscriptionData);
         
            const addedSubscription = response.data.data;
    
            setSubscriptions([addedSubscription, ...subscriptions]);
           
            setRecentlyAdded(true);
            setTimeout(() => {
                setRecentlyAdded(false);
                fetchSubscriptions();
            }, 3000);
            setAdding(false);
            setNewSubscription({ time: '', price: '' }); 
        } catch (error) {
            console.error(error);
        }
    };
    

    const handleEditSubscription = async () => {
        try {
            const subscriptionData = {
                ...editSubscription,
                time_quantity_type: "minutes"
            };
            await carParkServices.hugAndFly.edit(editingId, subscriptionData);
            fetchSubscriptions();
            setRecentlyUpdated(editingId);
            setTimeout(() => setRecentlyUpdated(null), 3000);
            setEditingId(null);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchSubscriptions();
    }, [i18n.language]);

    const fetchSubscriptions = async () => {
        try {
            const response = await carParkServices.hugAndFly.list();
            setSubscriptions(response.data.data);
            if (recentlyAdded) {
                setRecentlyAdded(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteSubscription = async (id) => {
        try {
            await carParkServices.hugAndFly.delete(id);
            fetchSubscriptions();
            setNotification({ show: true, message: 'car-park-deleted' });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container>
            <Table>
                <thead>
                    <TableRow>
                        <TableHead>{t("time")}</TableHead>
                        <TableHead>{t("price")}</TableHead>
                        <TableHead>{t("actions")}</TableHead>
                    </TableRow>
                </thead>
                <tbody>
                    <AddRow>
                        <td colSpan="3">
                            {!adding && <AddButton onClick={() => setAdding(true)}>{t("add-new-option")}</AddButton>}
                        </td>
                    </AddRow>
                    {adding && (
                        <TableRow $isEditing>
                            <TableData>
                                <Input
                                    type="text"
                                    placeholder={t("time")}
                                    onChange={(e) => setNewSubscription({ ...newSubscription, time: e.target.value })}
                                />
                            </TableData>
                            <TableData>
                                <Input
                                    type="text"
                                    placeholder={t("price")}
                                    onChange={(e) => setNewSubscription({ ...newSubscription, price: e.target.value })}
                                />
                            </TableData>
                            <TableData>
                                <Button onClick={handleAddSubscription}><img src={checkmarkIcon} alt="Save" /></Button>
                                <Button onClick={() => setAdding(false)}><img src={cancelIcon} alt="Cancel" /></Button>
                            </TableData>
                        </TableRow>
                    )}
                    {subscriptions.map((subscription, index) => (
                        <TableRow
                            key={subscription.id}
                            $isEditing={editingId === subscription.id}
                            $isRecentlyUpdated={recentlyUpdated === subscription.id || recentlyAdded && index === 0}
                        >
                            {editingId === subscription.id ? (
                                <>
                                    <TableData>
                                        <Input
                                            type="text"
                                            value={editSubscription.time}
                                            onChange={(e) => setEditSubscription({ ...editSubscription, time: e.target.value })}
                                        />
                                    </TableData>
                                    <TableData>
                                        <Input
                                            type="text"
                                            value={editSubscription.price}
                                            onChange={(e) => setEditSubscription({ ...editSubscription, price: e.target.value })}
                                        />
                                    </TableData>
                                    <TableData>
                                        <Button onClick={handleEditSubscription}><img src={checkmarkIcon} alt="Save" /></Button>
                                        <Button onClick={() => setEditingId(null)}><img src={cancelIcon} alt="Cancel" /></Button>
                                    </TableData>
                                </>
                            ) : (
                                <>
                                    <TableData>{subscription.time}</TableData>
                                    <TableData>{subscription.price}</TableData>
                                    <TableData>
                                        <Button onClick={() => handleEditStart(subscription)}><img src={editIcon} alt="Edit" /></Button>
                                        <Button onClick={() => handleDeleteSubscription(subscription.id)}><img src={deleteIcon} alt="Delete" /></Button>
                                    </TableData>
                                </>
                            )}
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            {notification.show && <Notification text={notification.message} />}
        </Container>
    );
};

export default CarHugAndFlyTable;
