/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './LoadingScreen.css';
import logo_tav from '../../assets/images/logo-tav.png';
import Spinner from 'react-bootstrap/Spinner';


const LoadingScreen = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/en/welcome');
    }, 3000)
  })
  
  return (
    <div className="loading-screen">
      <div className="logo">
        <img src={logo_tav} alt="logo-tav" />
      </div>
      <div className="loading-icon ">
        <Spinner id='spinner' className='spinner' animation="border" role="status">
        </Spinner>
      </div>
    </div>
  );
};

export default LoadingScreen;