import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import mapServices from '../../services/mapServices';

const Container = styled.div`
  text-align: center;
  color: #031F73;
  width: 90%;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Text = styled.div`
  font-size: 1rem;
  color: #031F73 !important;
  text-decoration: underline;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const Click = styled.div`
  // Additional styles if needed
`;

const AddButton = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  background-color: #031F73;
  width: 100%;
  padding: 0.25rem;
  text-align: center;
  cursor: pointer;
`;

const GlobalPhotoUpload = ({ type_name }) => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const data = await mapServices.getGlobalMapData(type_name);
        setImageUrl(data.data.map); 
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [type_name]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(URL.createObjectURL(file));
  };

  const handleSubmit = async () => {
    if (!image) {
      alert('Please select an image to upload.');
      return;
    }

    try {
      const response = await mapServices.uploadMap(image, type_name);
      console.log(response);
      alert('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Failed to upload image.');
    }
  };

  return (
    <Container>
      {imageUrl && <Image src={imageUrl} alt="Global Map" />}
      <Text onClick={() => document.getElementById('fileInput').click()}>
        <Click>
          Upload global photo for {type_name} destination (310px*260px)
        </Click>
      </Text>
      <FileInput
        type="file"
        id="fileInput"
        accept="image/*"
        onChange={handleFileChange}
      />
      <AddButton onClick={handleSubmit}>Save</AddButton>
    </Container>
  );
};

export default GlobalPhotoUpload;
