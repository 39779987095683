import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardContainer = styled(Link)`
  min-width: 35%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  margin-right: 0.75rem;
  text-decoration: none; 
  padding: 2rem 0.5rem;
  @media (min-width: 1180px) {
    min-width:20%;
    max-width:35%;
  }
`;

const Text = styled.div`
  font-size: 1.375rem;
  font-weight: bold;
  color: #fff;
  @media (min-width: 768px) {
    font-size:1.5rem;
  }
`;

const Card = ({ text, backgroundImage, to }) => {


  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <CardContainer to={to} style={cardStyle}>
      <Text>{text}</Text>
      <Text>&gt;</Text>
    </CardContainer>
  );
};

export default Card;