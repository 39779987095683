import axios from 'axios';
import i18n from 'i18next';
import {
    DASHBOARD_SETTINGS_GET_TEXT,
    DASHBOARD_SETTINGS_PUBLISH_TEXT
} from './endpoints';

const getCurrentLanguage = () => i18n.language;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const currentLanguage = getCurrentLanguage();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['Accept-Language'] = currentLanguage; 
  return config;
});

const token = localStorage.getItem('accessToken');

const config = {
  headers: { 
    Authorization: `Bearer ${token}`,
    'Accept-Language': getCurrentLanguage() 
  }
};


const dashboardSettings = {
   
    get: (key) => axios.get(DASHBOARD_SETTINGS_GET_TEXT.replace('%(key)s', key), config),

  
    publish: (key, data) => axios.put(DASHBOARD_SETTINGS_PUBLISH_TEXT.replace('%(key)s', key), data, config),
};

export default dashboardSettings;
