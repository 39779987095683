import { UPLOAD_MAPS_ENDPOINT,GLOBAL_MAP_GET } from './endpoints';
import axios from 'axios';

export const uploadMap = async (image, type_name) => {
  if (!image) {
    throw new Error('No image file provided');
  }

  const formData = new FormData();
  formData.append('file', image);


  const formattedEndpoint = UPLOAD_MAPS_ENDPOINT.replace('%(type_name)s', type_name);

  const response = await axios.post(formattedEndpoint, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};


const getGlobalMapData = async (type_name) => {
  try {
    const formattedEndpoint = GLOBAL_MAP_GET.replace('%(type_name)s', type_name);
    const response = await axios.get(formattedEndpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching global map data:', error);
    throw error;
  }
};

export default { uploadMap ,getGlobalMapData};