import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const NotificationPopup = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: ${slideDown} 0.5s ease forwards;
  display: ${props => props.show ? 'block' : 'none'};
`;

const Notification = ({ text }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000); // Show the notification for 2 seconds

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, [text]);

  return (
    <NotificationPopup show={show}>
      {t(text)}
    </NotificationPopup>
  );
};

export default Notification;
