import React from 'react';
import Container from '../../components/manage-landing-components/Container';
import Card  from '../../components/manage-landing-components/Card';
import taxiImage from "../../assets/../assets/images/taxi.jpg";
import rentACarImage from "../../assets/images/rentACarImg.jpg";
import carParkImage from "../../assets/images/parkImg.jpg";
import busImage from "../../assets/images/busImg.jpg";
import styled from 'styled-components';
import { useTranslation, withTranslation } from 'react-i18next';



const CardContainer=styled.div`
display:flex;
height:100vh;
justify-content:center;
padding-top:1.5rem;
padding-bottom:1.5rem;
`

const Dashboard = () => {
  const { t,i18n } = useTranslation();
  return (
    <Container>
      <CardContainer>
      <Card text={t('taxi')} backgroundImage={taxiImage} to="taxi" />
        <Card text={t('rent-a-car')}  backgroundImage={rentACarImage} to="rent-a-car" />
        <Card text={t('car-park')}  backgroundImage={carParkImage} to="car-park" />
        <Card text={t('bus')} backgroundImage={busImage} to="bus" />
   
      </CardContainer>
        </Container>
  );
};

export default Dashboard;
