import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import CarParkingTable from "../../components/manage-landing-components/CarParkingTable";
import CarHugAndFlyTable from "../../components/manage-landing-components/CarHugAndFlyTable";
import CarSubscriptionTable from "../../components/manage-landing-components/CarSubscriptionTable";
import LanguageTabsComponent from './ShowOnLandingPage';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
    margin-top: 20px;

  @media screen and (min-width: 767px) {
    height:70vh;
  }`
  ;

const TableContainer = styled.div`
width:100%;
height:29vh;
overflow:auto;

@media screen and (min-width: 767px) {
  height:50vh;
  }`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.primary};
  @media (min-width: 768px) {
    font-size:1.25rem;
  }
`;

const StyledTh = styled.th`
  font-weight: bold;
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
`;

const StyledTd = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;


const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const OptionContainer = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$active ? "#031F73" : "#031F73" + '20')};
  color: ${(props) => (props.$active ? 'white' : "#031F73"+ '50')};
  padding: 2px 0;
  cursor: pointer;
  text-align: center;
  
  &.flexOption {
    flex: 0.48; 
  }
  &.thirdOption {
    flex: 1; 
  }
  @media (min-width: 768px) {
    font-size:1.5rem;
  }
  ${(props) =>
    props.$active &&
    css`
      box-shadow: 0px 4px 4px 0px #00000040;
      background-color:#031F73;
      color: white;
    `}
`;
const CarParkTable = () => {
  const [selectedTable, setSelectedTable] = useState('Parking');


  const { t } = useTranslation();

  

  const handleButtonClick = (table) => {
    setSelectedTable(table);
  };

  
  const renderTable = () => {
    switch (selectedTable) {
      case 'Parking':
        return <CarParkingTable />

      case 'Hug&Fly':
        return <CarHugAndFlyTable  />;

      default:
        return null;
    }
  };

  const renderInput = () => {
    switch (selectedTable) {
      case 'Parking':
        return <LanguageTabsComponent keyName="parking"/>

      case 'Hug&Fly':
        return <LanguageTabsComponent keyName="hug_and_fly" />;
        default:
        return null;
    }
  };

  return (
    <Container>
      <RowContainer>
        <OptionContainer className='flexOption' $active={selectedTable === 'Parking'} onClick={() => handleButtonClick('Parking')}>
          {t('parking')}
        </OptionContainer>
        <OptionContainer className='flexOption' $active={selectedTable === 'Hug&Fly'} onClick={() => handleButtonClick('Hug&Fly')}>
          {t('hugAndFly')}
        </OptionContainer>
      </RowContainer>
      <RowContainer>
        {/* <OptionContainer className='thirdOption' $active={selectedTable === 'Hug&Fly'} onClick={() => handleButtonClick('Hug&Fly')}>
          {t('hugAndFly')}
        </OptionContainer> */}
      </RowContainer>
      <TableContainer>{renderTable()}</TableContainer>
      <TableContainer>{renderInput()}</TableContainer>
    </Container>
  );
};

export default CarParkTable;