/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect,useCallback } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import download_ic from '../../assets/icons/download-ic.png';
import axios from 'axios';
import PaginationTest from './PaginationTest';
import { useTranslation } from 'react-i18next'; 
import logout_ic from '../../assets/icons/logout-ic.png';
import env from '../../assets/env.js'
import debounce from 'lodash.debounce';


const TableDash = ({ 
  handleShowDownloadModal,  
  currentPage,
  sortOrder,
  onPageChange,
  searchQuery,
  onSearchButtonClick
}) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();


  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(env.API_URL+'/logout', {
        mode: 'cors',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        localStorage.removeItem('accessToken'); 
        localStorage.removeItem('userRole');
        localStorage.removeItem('email');

        navigate(`/en/login`);
      } else {
        console.error('Logout failed:', response.status);
        navigate(`/en/login`);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };


  const fetchData = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(env.API_URL+'/dashboard/hotspot-users/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          search: searchQuery,
          per_page: '8',
          page: currentPage.toString(),
          sort_by: 'total_time_in_last_7_days',
          order_by: sortOrder,
        },
      });
      if (response.data && response.data.data) {
        // console.log(response.data.data);
        setUserData(response.data.data);
        setTotalPages(response.data.meta.last_page);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // fetch data on mount
  useEffect(() => {
    fetchData();
}, [currentPage, searchQuery, sortOrder]);



  return (
    <>
    <div className='table-responsive mt-4'>
      <table className="table">
        <thead>
          <tr  className='text-center'>
            <th id='table-data' className='border-end' scope="col">{t('Email')}</th>
            <th id='table-data' className='border-end' scope="col">{t('Phone Number')}</th>
            <th id='table-data' className='border-end' scope="col">{t('Type of user')}</th>
            <th id='table-data' className='border-end' scope="col">{t('Age')}</th>
            <th id='table-data' className='border-end' scope="col">{t('Gender')}</th>
            <th id='table-data' className='border-end' scope="col">{t('MAC Address')}</th>
            <th id='table-data' scope="col" className='border-end'>{t('Online in last 7 days')}</th>
            <th id='table-data' className='border-0 justify-content-start d-flex m-0 p-0 '> <Link onClick={handleShowDownloadModal}><img src={download_ic} alt="Your Logo" /></Link></th>
          </tr>
        </thead>
        <tbody className=''>
          {userData.map((user) => (
            <tr key={user.id}>
              <td id='table-data' className='border-end'>{user.email}</td>
              <td id='table-data' className='border-end'>{user.phone_number}</td>
              <td id='table-data' className='border-end'>{user.type.name}</td>
              <td id='table-data' className='border-end'>{user.age.slug}</td>
              <td id='table-data' className='border-end'>{user.gender.name}</td>
              <td id='table-data' className='border-end'>{user.last_device_mac}</td>
              <td className='border'>
                {user.total_time_in_last_7_days} 
                {user.is_online ? <span className='circle'></span> : null}
              </td>
            </tr>
          ))}
          <tr id='last-row'>
            <td id='table-data'></td>
            <th id='table-data'></th>
            <td id='table-data'></td>
            <td id='table-data'></td>
            <td id='table-data'></td>
            <td id='table-data'></td>
            <td id='table-data'>{t('Active Users')} <span className='circle'></span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className='col-xl-12'>
    <div className='row'>
      <div className='col-sm-2'>
        <img className='img_signout' src={logout_ic} alt="signout" />
        <Link className='fw-bold' id='change-color' onClick={handleLogout}>{t('SIGN OUT')}</Link>
      </div>
      <div className='col-sm-8 justify-content-center d-flex'>
        <PaginationTest
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
      <div className='col-xs-2'>
        
      </div>
    </div>
  </div>
  </>
  );
};

export default TableDash;