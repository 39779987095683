import React, { useState,useEffect } from 'react';
import { Modal, Button, Form, Row, Col, ModalTitle } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import './GlobalRulesModal.css';
import { useTranslation } from 'react-i18next';
import clear_rules from '../../assets/icons/clear-rules-ic.png';
import env from '../../assets/env.js'
import debounce from 'lodash/debounce';
import axios from 'axios';

const GlobalRulesModal = ({
    show,
    handleClose,
    handleSubmit,
}) => {
  const { t,i18n } = useTranslation(); 
  const [restrictionRange, setRestrictionRange] = useState('')
  const [usageTime, setUsageTime] = useState('')
  const [conditionRange, setConditionRange] = useState('')
  const [options, setOptions] = useState([]);
  const [globalRulesSet, setGlobalRulesSet] = useState(false);
  const [clearRules, setClearRules] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errMsg, setErrMsg] = useState('');

  const updateDevice = async (deviceId, exclude) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${env.API_URL}/dashboard/hotspot-devices/edit/${deviceId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ exclude_internet_rules: exclude }),
        });

        if (response.status === 200) {
            const data = await response.json();
            // console.log(`Device ${deviceId} updated. Exclude: ${exclude}`, data);
        } else {
            const error = await response.json();
            console.log(`Error updating device ${deviceId}:`, error);
        }
    } catch (error) {
        console.error(`Error updating device ${deviceId}:`, error);
    }
};
  const handleChange = async (selected) => {
    const newlySelectedOption = selected.find(option => !selectedOptions.some(selectedOption => selectedOption.value === option.value));
    const deselectedOption = selectedOptions.find(selectedOption => !selected.some(option => option.value === selectedOption.value));

    if (newlySelectedOption) {
        await updateDevice(newlySelectedOption.value, true);
    }
    if (deselectedOption) {
        await updateDevice(deselectedOption.value, false);
    }
    setSelectedOptions(selected);
};
  

  const handleRestrictionRangeChange = (e) => {
    setRestrictionRange(e.target.value); 
  };
//TODO: ima setGlobalRulesSet(true) i setClearRules(true)
const handleUsageTimeChange = (e) => {
    setUsageTime(e.target.value);
};

const fetchInitialSelectedOptions = async () => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${env.API_URL}/dashboard/hotspot-devices/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        'excluded': 1,
      },
    });

    if (response.data && response.data.data) {
      // console.log(response.data.data);
      const initialOptions = response.data.data.map(user => ({
        value: user.id, 
        label: `${user.email} (${user.mac_address})`,
      }));
      setSelectedOptions(initialOptions);
    }
  } catch (error) {
    console.error('Error fetching initial selected options:', error);
  }
};

const debouncedFetchData = debounce(async (inputValue, callback) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${env.API_URL}/dashboard/hotspot-devices/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: inputValue,
        excluded: 0,
        per_page: '',
        page: '1'
      },
    });

    if (response.data && response.data.data) {
      const options = response.data.data.map(device => ({
        value: device.id,
        label: `${device.email} (${device.mac_address})`,
      }));
      callback(options);
    } else {
      callback([]);
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    callback([]);
  }
}, 300);

const fetchDataOptions = (inputValue, callback) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(inputValue)) { 
    debouncedFetchData(inputValue, callback);
  } else {
    callback([]); 
  }
};

const adjustUsageTime = async (increment) => {
  const [hours, minutes] = usageTime.split(':').map(Number);

  let newTime, totalMinutes;
  if (increment === 'up') {
      totalMinutes = hours * 60 + minutes + 15;
      if (totalMinutes >= 24 * 60) {
          newTime = '00:15'; // Go to 00:15 after 24:00
      } else {
          const newHours = Math.floor(totalMinutes / 60) % 24;
          const newMinutes = totalMinutes % 60;
          newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
      }
  } else if (increment === 'down') {
      if (hours === 0 && minutes <= 15) {
          newTime = '24:00'; // Wrap back to 24:00 from 00:15 or less
      } else {
          totalMinutes = (hours * 60 + minutes - 15 + 24 * 60) % (24 * 60);
          const newHours = Math.floor(totalMinutes / 60) % 24;
          const newMinutes = totalMinutes % 60;
          newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
      }
  } else {
      return; // Exit if increment is neither 'up' nor 'down'
  }

  setUsageTime(newTime);
  try {
      const token = localStorage.getItem('accessToken');
      const url = new URL(env.API_URL + `/dashboard/hotspot-settings/edit/usage_time`);
      const response = await fetch(url, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ value: newTime }),
      });

      if (response.ok) {
          const data = await response.json();
          // console.log("Usage time updated", data);
          setErrMsg('');
      } else {
          const error = await response.json();
          console.log(error);
          throw new Error('Failed to update usage time');
      }
  } catch (error) {
      setErrMsg('Too Many Requests. Please try again later!');
      console.error("Error updating usage time:", error);
  }
};


  const fetchGlobalRulesData = async (event) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(env.API_URL+'/dashboard/hotspot-settings/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        // console.log(data);
        setRestrictionRange(data.data[0].value)
        setUsageTime(data.data[1].value)
        setConditionRange(data.data[2].value)
      } else {
        const error = await response.json();
        console.log(error);
        throw new Error('Failed to fetch user');
      }
      } catch (error) {
      console.error('Failed to fetch user:', error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchGlobalRulesData();
      fetchInitialSelectedOptions();
    }
  }, [show]);

  const increaseRestrictionRange = async () => {
      const newRange = parseInt(restrictionRange, 10) + 1;
      // console.log(newRange);
      setRestrictionRange(newRange);
      try {
        const token = localStorage.getItem('accessToken');
        const url = new URL(env.API_URL + `/dashboard/hotspot-settings/edit/restriction_range`);
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ 
            'value': JSON.stringify(newRange)
          }),
        });
        if (response.ok) {
          const data = await response.json();
          // console.log(data);
          // console.log("Range increment");
          setErrMsg('');
      } else {
        const error = await response.json();
        console.log(error);
        throw new Error('Failed to decrement range');
      }
    } catch (error) {
        setErrMsg('Too Many Requests. Please try again later!');
        console.error("Error incrementing range:", error);
    }
  };
  const decreaseRestrictionRange = async () => {
    if (restrictionRange > 1) {
        const newRange = parseInt(restrictionRange, 10) - 1;
        // console.log(newRange);
        setRestrictionRange(newRange);
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${env.API_URL}/dashboard/hotspot-settings/edit/restriction_range`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                   'value': JSON.stringify(newRange) 
                }),
            });

            if (response.ok) {
              const data = await response.json();
              // console.log(data);
              // console.log("Range decremented");
              setErrMsg('');
            } else {
              const error = await response.json();
              console.log(error);
              throw new Error('Failed to decrement range');
            }
        } catch (error) {
            setErrMsg('Too Many Requests. Please try again later!');
            console.error("Error decrementing range:", error);
        }
    }
};

const handleClearRules = async () => {
  try {
    const token = localStorage.getItem('accessToken');

    // Update restriction_range
    await fetch(`${env.API_URL}/dashboard/hotspot-settings/edit/restriction_range`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ value: '1' }),
    });

    // Update usage_time
    await fetch(`${env.API_URL}/dashboard/hotspot-settings/edit/usage_time`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ value: '24:00' }),
    });

    console.log("Rules cleared");
    setRestrictionRange('1');
    setUsageTime('24:00');
  } catch (error) {
    console.error("Error clearing rules:", error);
  }
};

  return (
    <Modal
      show={show}
      onHide={() => {
        setClearRules(false);
        setGlobalRulesSet(false);
        handleClose();
        setErrMsg('')
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    <Modal.Header closeButton>
      </Modal.Header>
      {globalRulesSet ? (
              <p className='fw-bold' id='change-color'>{t('You have successfully set new global rules!')}</p>
        ) : (
      <Modal.Body className='pt-3 px-5 py-5'>
        {clearRules ? (
              <p className='fw-bold' id='change-color'>{t('You have successfully cleared global rules!')}</p>
        ) : (
        <>
        <h5 id='change-color'>{t('Global Rules')}</h5>
        <Form onSubmit={handleSubmit} className="form-group">
          <Row className="mb-3">
            <Form.Group controlId="formPeriod" as={Col} className="d-flex align-items-center">
                <Form.Label column sm={3} id='change-color'>{t('For period of')}</Form.Label>
                <Col sm={9} className="d-flex align-items-center">
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      name="period"
                      value={restrictionRange}
                      onChange={handleRestrictionRangeChange}
                      min={1}
                      className="rounded-0"
                      disabled
                    />
                    <span id='change-color' className="input-group-text">{t('day(s)')}</span>
                    <Button variant="outline-secondary" onClick={increaseRestrictionRange} className="no-radius-button">
                      <i className="bi bi-arrow-up">↑</i>
                    </Button>
                    <Button variant="outline-secondary" onClick={decreaseRestrictionRange} className="no-radius-button">
                      <i className="bi bi-arrow-down">↓</i>
                    </Button>
                  </div>
                </Col>
              </Form.Group>
            </Row>

          <Row className="mb-3">
            <Form.Group controlId="formLimitUsageTime" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3} id='change-color'>{t('Limit usage time')}</Form.Label>
              <Col sm={9} className="d-flex align-items-center">
                <div className="input-group">
                  <Form.Control
                      type="text"
                      value={usageTime}
                      onChange={handleUsageTimeChange}
                      className="rounded-0"
                      disabled
                  />
                  <span id='change-color' className="input-group-text">{t('hour(s)')}</span>
                  <Button className="no-radius-button" variant="outline-secondary" onClick={() => adjustUsageTime('up')}>
                      <i className="bi bi-arrow-up">↑</i>
                  </Button>
                  <Button className="no-radius-button" variant="outline-secondary" onClick={() => adjustUsageTime('down')}>
                      <i className="bi bi-arrow-down">↓</i>
                  </Button>
                </div>
              </Col>
            </Form.Group>
          </Row>

          <Row className="">
            <Form.Group as={Col} className="d-flex align-items-center">
              <Col sm={12}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={fetchDataOptions}
                placeholder={t('Exclude the users below from the global rule')}
                isMulti
                onChange={handleChange}
                value={selectedOptions}
                className='select-css'
                isClearable={false}
              />
              </Col>
            </Form.Group>
          </Row>
          {errMsg && <p className="error-message text-danger">{errMsg}</p>}
          {/* Button commented so it wont be used because the data is updated on arrow up and down and from select */}
          {/* <Row className='mt-4'>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Button onClick={handleClose} variant="btn btn-primary rounded-0 w-100 fs-3" id='login-button' type="submit" className='rounded-0'>
                {t('Set rules')}
              </Button>
            </Col>
            <Col sm={2}></Col>
          </Row> */}
          <Row className=''>
            <Col sm={3}></Col>
            <Col sm={6}>
            <Button id='change-color' variant="outline-secondary mt-2 w-100 border-0 opacity-25" onClick={handleClearRules} className='rounded-0 text-decoration-underline'>
              {t('Clear rules')} <img src={clear_rules} id='clear-rules-ic' alt='clear-rules-ic' className='mx-2' width={22}/>
            </Button>
            </Col>
            <Col sm={3}></Col>
            <Col xl={12} id='change-color' className='opacity-25 text-center'>
              {t('*By clearing rules you enable users for unlimited WIFI usage')}
            </Col>
          </Row>
        </Form>
        </>
        )}
      </Modal.Body>
      )}
    </Modal>
  );
};

export default GlobalRulesModal;
