import axios from 'axios';
import i18n from 'i18next';
import { DASHBOARD_TAXI_ROUTE_LIST, DASHBOARD_TAXI_ROUTE_STORE, DASHBOARD_TAXI_ROUTE_EDIT, DASHBOARD_TAXI_ROUTE_DELETE } from './endpoints';



const getCurrentLanguage = () => i18n.language;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const currentLanguage = getCurrentLanguage();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['Accept-Language'] = currentLanguage; 
  return config;
});

const token = localStorage.getItem('accessToken');

const config = {
  headers: { 
    Authorization: `Bearer ${token}`,
    'Accept-Language': getCurrentLanguage() 
  }
};

export const listDashboardTaxiRoutes = async () => {
  return axios.get(DASHBOARD_TAXI_ROUTE_LIST, config);
};

export const createDashboardTaxiRoute = async (data) => {
  return axios.post(DASHBOARD_TAXI_ROUTE_STORE, data, config);
};

export const updateDashboardTaxiRoute = async (id, data) => {
  return axios.put(DASHBOARD_TAXI_ROUTE_EDIT.replace('%(id)s', id), data, config);
};

export const deleteDashboardTaxiRoute = async (id) => {
  return axios.delete(DASHBOARD_TAXI_ROUTE_DELETE.replace('%(id)s', id), config);
};
