import React, { useState, useEffect } from 'react';
import { listDashboardTaxiRoutes, createDashboardTaxiRoute, updateDashboardTaxiRoute, deleteDashboardTaxiRoute } from '../../services/taxiServices';
import checkmarkIcon from "../../assets/icons/accept.svg";
import cancelIcon from "../../assets/icons/cancel.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import {
    Container, Table, Button, AddButton, Input, TableRow, TableHead, AddRow, TableData
} from "../../components/manage-landing-components/Table";
import { useTranslation, withTranslation } from 'react-i18next';
import Notification from './Notification';
import LanguageTabsComponent from './ShowOnLandingPage';


const TaxiRoutesTable = () => {
    const { t,i18n } = useTranslation();
    const [routes, setRoutes] = useState([]);
    const [adding, setAdding] = useState(false);
    const [newRoute, setNewRoute] = useState({ destination: '', distance: '', price: '' });
    const [editingId, setEditingId] = useState(null);
    const [editRoute, setEditRoute] = useState({ destination: '', distance: '', price: '' });
    const [recentlyUpdated, setRecentlyUpdated] = useState(null);
    const [tempIdForNewRoute, setTempIdForNewRoute] = useState(null);
    const [recentlyAdded, setRecentlyAdded] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '' });
  

   





    const handleEditStart = (route) => {
        setEditingId(route.id);
        setEditRoute({ destination: route.destination, distance: route.distance, price: route.price });
    };
    const handleAddRoute = async () => {
        try {
            const response = await createDashboardTaxiRoute(newRoute);
           
            const addedRoute = response.data.data; 
    
            setRoutes([addedRoute, ...routes]); 
            
            setRecentlyAdded(true);
            setTimeout(() => {
                setRecentlyAdded(false);
                fetchRoutes();
            }, 3000);
            setAdding(false);
            setNewRoute({ destination: '', distance: '', price: '' });
        } catch (error) {
            console.error(error);
        }
    };
    
    
    
    
    
    

    const handleEditRoute = async () => {
        try {
            await updateDashboardTaxiRoute(editingId, editRoute);
            fetchRoutes();
            setRecentlyUpdated(editingId);
            setTimeout(() => setRecentlyUpdated(null), 3000);
            setEditingId(null);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchRoutes();
    }, [i18n.language]);

    const fetchRoutes = async () => {
        try {
            const response = await listDashboardTaxiRoutes();
            setRoutes(response.data.data);
            if (recentlyAdded) {
                setRecentlyAdded(false);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const handleDeleteRoute = async (id,destination) => {
        try {
            await deleteDashboardTaxiRoute(id);
            fetchRoutes();
            setNotification({ show: true, message: 'taxi-deleted' });
            
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
        <Container>
            <Table>
                <thead>
                    <TableRow>
                        <TableHead>{t("destination")}</TableHead>
                        <TableHead>{t("distance")}</TableHead>
                        <TableHead>{t("price")}</TableHead>
                        <TableHead>{t("actions")}</TableHead>
                    </TableRow>
                </thead>


                <tbody>
                    <AddRow><td colSpan="4">{!adding && <AddButton onClick={() => setAdding(true)}>{t("add-new-taxi")}</AddButton>}</td></AddRow>

                    {adding && (
                        <TableRow $isEditing>
                            <TableData><Input type="text" placeholder="Destination" onChange={(e) => setNewRoute({ ...newRoute, destination: e.target.value })} /></TableData>
                            <TableData><Input type="text" placeholder="Distance" onChange={(e) => setNewRoute({ ...newRoute, distance: e.target.value })} /></TableData>
                            <TableData><Input type="text" placeholder="Price" onChange={(e) => setNewRoute({ ...newRoute, price: e.target.value })} /></TableData>
                            <TableData>
                                <Button onClick={handleAddRoute}><img src={checkmarkIcon} alt="Save"></img></Button>
                                <Button onClick={() => setAdding(false)}><img src={cancelIcon} alt="Cancel"></img></Button>
                            </TableData>
                        </TableRow>
                    )}
                    {routes.map((route,index) => (
                        
                        <TableRow key={route.id}
                            $isEditing={editingId === route.id}
                            $isRecentlyUpdated={recentlyUpdated === route.id || recentlyAdded && index === 0}>
                            {editingId === route.id ? (
                                <>
                                    <TableData><Input type="text" value={editRoute.destination} onChange={(e) => setEditRoute({ ...editRoute, destination: e.target.value })} /></TableData>
                                    <TableData><Input type="text" value={editRoute.distance} onChange={(e) => setEditRoute({ ...editRoute, distance: e.target.value })} /></TableData>
                                    <TableData><Input type="text" value={editRoute.price} onChange={(e) => setEditRoute({ ...editRoute, price: e.target.value })} /></TableData>
                                    <TableData>
                                        <Button onClick={handleEditRoute}><img src={checkmarkIcon} alt="Save"></img></Button>
                                        <Button onClick={() => setEditingId(null)}><img src={cancelIcon} alt="Cancel"></img></Button>
                                    </TableData>
                                </>
                            ) : (
                                <>
                                    <TableData>{route.destination}</TableData>
                                    <TableData>{route.distance}</TableData>
                                    <TableData>{route.price}</TableData>
                                    <TableData>
                                        <Button onClick={() => handleEditStart(route)}><img src={editIcon} alt="Edit destination"></img></Button>
                                        <Button onClick={() => handleDeleteRoute(route.id,route.destination)}><img src={deleteIcon} alt="Delete destination"></img></Button>
                                    </TableData>
                                </>
                            )}
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            {notification.show && <Notification text={notification.message} />}

        </Container>
        <LanguageTabsComponent keyName="taxi" />
        </>
    );
};

export default TaxiRoutesTable;
