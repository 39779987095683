/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState, useEffect, useCallback} from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import logo_tav from '../assets/images/logo-tav.png';
import logout_ic from '../assets/icons/logout-ic.png';
import add_user_ic from '../assets/icons/add-user-ic.png';
import global_rules_ic from '../assets/icons/global-rules-ic.png';
import manage_landing_ic from '../assets/icons/manage-landing-ic.png';
import home_ic from '../assets/icons/home-ic.png';

import TableCorp from '../components/tables/TableCorp.js';
import GlobalRulesModal from '../components/modals/GlobalRulesModal.js';
import EditUserModal from '../components/modals/EditUserModal.js';


import './ManageCorporateUsers.css';
import AddUserModal from '../components/modals/AddUserModal.js';

import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import search_ic from '../assets/icons/search-ic.png';
import env from '../assets/env.js'
import debounce from 'lodash.debounce';


const ManageCorporateUsers = () => {
  const navigate = useNavigate();
  const [selectedTime, setSelectedTime] = useState('00:00');
  const [showAddUser, setShowAddUser] = useState(false);
  const [showGlobalRules, setShowGlobalRules] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('DESC'); // State to track sorting order
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const { t,i18n } = useTranslation();
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const debouncedSearch = useCallback(
    debounce((query) => {
      setCurrentPage(1);
      setDebouncedQuery(query);
    }, 500), 
    []
  );
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length >= 3) {
      debouncedSearch(query);
    }else if(query.length === 0 || query.length === 2) {
      debouncedSearch('');
    }
  };

  const handleSearchButtonClick = () => {
    setDebouncedQuery(searchQuery);
    setCurrentPage(1);
};
  useEffect(() => {
    document.title = 'Tav Manage Corporate';
  }, []);  
  const languageNames = {
    en: 'EN',
    mk: 'MK',
    al: 'AL',
  };

  const getCurrentLanguageName = () => {
    return languageNames[i18n.language] || 'Language';
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
      .then(() => {
        // console.log(`Language changed to: ${lng}`);
      })
      .catch((error) => {
        console.error('Error while changing language:', error);
      });
  };

  const handleTimeChange = (increment) => {
    const [hours, minutes] = selectedTime.split(':').map(Number);

    let newTime;
    if (increment === 'up') {
      const totalMinutes = hours * 60 + minutes + 15;
      const newHours = Math.floor(totalMinutes / 60) % 24;
      const newMinutes = totalMinutes % 60;
      newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    } else if (increment === 'down') {
      const totalMinutes = (hours * 60 + minutes - 15 + 24 * 60) % (24 * 60);
      const newHours = Math.floor(totalMinutes / 60);
      const newMinutes = totalMinutes % 60;
      newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    }
    setSelectedTime(newTime);
  };

  const [profileData, setProfileData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '', 
    userType: '', 
  });

  const [selectedUserData, setSelectedUserData] = useState({
    id: '',
    name: '',
    surname: '',
    email: '',
    password: '',
    userType: '',
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('Submitting edited profile:', profileData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const options = [
    { value: 'email1', label: 'example@example.com1' },
    { value: 'email2', label: 'example@example.com2' },
    { value: 'email3', label: 'example@example.com3' },
    { value: 'email4', label: 'example@example.com4' },
    { value: 'email5', label: 'example@example.com5' },
    { value: 'email6', label: 'example@example.com6' },
    { value: 'email7', label: 'example@example.com7' },
    { value: 'email8', label: 'example@example.com8' },
  ];
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
};

  const handleShowAddUser = () => setShowAddUser(true);

  const handleCloseGlobalRules = () => setShowGlobalRules(false);
  const handleShowGlobalRules = () => setShowGlobalRules(true);

  const handleSortOrderChange = (newOrder) => {
    setCurrentPage(1); 
    setSortOrder(newOrder); 
  };
   const handleShowEditUser = async (userId) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(env.API_URL+`/dashboard/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = response.data; 
      // console.log(userData);

      setSelectedUserData({
        id: userData.data.id,
        name: userData.data.name,
        surname: userData.data.surname,
        email: userData.data.email,
        role: userData.data.role,
      });

      setShowEditUser(true); 
    } catch (error) {
      // console.log("test");
      console.error('Error fetching user data:', error);
    }
  };

    return (
      <div  className="index-screen mt-0">
        <div className="container-fluid">
          <div className="row">
            <div id='language-selector' className='col-sm-3'>
            <Link id='change-color' className='fw-bold' to={`/${i18n.language}/index`}><img className='img_signout' src={home_ic} alt="Home" />{t('Home')}</Link>
            </div>
            <div className='col-sm-6 text-center'>
              <div className="center-logo">
                <Link to={`/${i18n.language}/index/`}>
                  <img src={logo_tav} alt="Your Logo" />
                </Link>             
              </div>
            </div>
            <div id='language-selector' className='col-sm-3 d-flex justify-content-end p-0'>
              <Link className='fw-bold' id='smaller-header-links' to={`/${i18n.language}/index/manage-landing-page`}>
                <img className='img_signout' src={manage_landing_ic} alt="Your Logo" />{t('Manage landing page info')}
              </Link>            
              <DropdownButton title={`${getCurrentLanguageName()}↓`} variant="btn fs-5" id="dropdown-basic">
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('mk')}>Macedonian</Dropdown.Item>
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('al')}>Albanian</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className='row'>
          <div className='col-lg-3 d-flex align-items-center'>
                <input
                id='change-color-search'
                className='form-control w-75 rounded-0 flex-grow-1  '
                type="text"
                placeholder={t('Search')}
                value={searchQuery}
                onChange={handleSearchInputChange}
                style={{ height: '2.2rem' }}
              />
              <Link onClick={handleSearchButtonClick} variant='primary' className=''>
                <img className='' src={search_ic} alt='Search Icon' style={{ height: '2.2rem' }}/>
              </Link>
            </div>
            <div className='col-md-9 text-end'>
              <span><a id='change-color' onClick={handleShowAddUser} className='mx-1 fw-bold ' href='#'>{t('Add User')}<img className='img_signout me-4 mx-1' src={add_user_ic} alt="Your Logo" /></a></span>
              <label className='fw-bold' id='change-color'>{t('Sort by')}:</label>
              <span id='change-color' className=''>
            <Link
              id='change-color'
              className={`mx-1 ${sortOrder === 'DESC' ? '' : 'text-decoration-none'}`}
              onClick={() => handleSortOrderChange('DESC')}
            >
              {t('Most active')}
            </Link> |
            <Link
              id='change-color'
              className={`mx-1 ${sortOrder === 'ASC' ? '' : 'text-decoration-none'}`}
              onClick={() => handleSortOrderChange('ASC')}
            >
              {t('Least active')}
            </Link> 
          </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
            <TableCorp
            currentPage={currentPage}
            sortOrder={sortOrder}
            handleShowEditUser={handleShowEditUser}
            onPageChange={handlePageChange}
            searchQuery={debouncedQuery} 
            shouldFetchData={shouldFetchData}
            onSearchButtonClick={handleSearchButtonClick}
            resetFetchDataTrigger={() => setShouldFetchData(false)}
          />

            </div>
          </div>
        </div>
        <EditUserModal
          show={showEditUser}
          profileData={selectedUserData}
          handleCloseEditUser={() => {
            setShouldFetchData(true);
            setShowEditUser(false);
          }}
        />
      <AddUserModal
        show={showAddUser}
        handleClose={() => {
          setShowAddUser(false);
          setShouldFetchData(true);
        }}
        handleSubmit={handleSubmit}
        profileData={profileData}
        handleInputChange={handleInputChange}
      />
      <GlobalRulesModal
      show={showGlobalRules}
      handleClose={handleCloseGlobalRules}
      handleSubmit={handleSubmit}
      profileData={profileData}
      handleInputChange={handleInputChange}
      selectedTime={selectedTime}
      setSelectedTime={setSelectedTime}
      handleTimeChange={handleTimeChange}
      selectedOptions={selectedOptions}
      handleChange={handleChange}
      options={options}
    />
    </div>
    );
};

export default ManageCorporateUsers;
