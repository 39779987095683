import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { editRentACarOperator } from '../../services/rentACarServices';
import { useTranslation } from 'react-i18next';

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const Button = styled.button`
    background-color: #031F73;
    color: white;
    padding: 0.5rem 2.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    border: none;
    margin-top: 2rem;
`;

const PopupContent = styled.div`
    background: white;
    padding: 2rem 3rem;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    width: 50%;
    z-index: 1001;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    color: #031F73;
`;

const CloseButton = styled.div`
    float: right;
    color: #031F73;
    cursor: pointer;
`;

const Label = styled.label`
    color: #031F73;
    min-width: 20%;
    font-weight: 600;
`;

const Flex = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    border-bottom: 1px solid rgba(3, 31, 115, 0.1);
`;

const Input = styled.input`
    border: none;
    padding: 0.75rem 1rem;
    color: #031F73;
    &:focus {
        background-color: rgba(3, 31, 115, 0.1);
    }
    border-left: 1px solid rgba(3, 31, 115, 0.1);
    outline: none;
    cursor: pointer;
    width: 100%;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadText = styled.div`
  font-size: 1rem;
  color: #031F73;
  text-decoration: underline;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;

const OperatorEditPopup = ({ operator, onClose }) => {
    const { t } = useTranslation();

    const initialContacts = [
        { phone_number: '', web_site: '', email: '' },
        { phone_number: '', web_site: '', email: '' }
    ];
    const [operatorData, setOperatorData] = useState({
        name: '',
        file: null,
        work_from: '',
        work_to: '',
        contacts:initialContacts
    });
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        if (operator) {
            setOperatorData({
                ...operator,
                file: null // Reset file when operator changes
            });
            if (operator.company_logo) {
                setLogoUrl(operator.company_logo);
            }
        }
    }, [operator]);
    
    const handleInputChange = (event, field, contactIndex = null) => {
        if (field.includes('contacts')) {
            const updatedContacts = [...operatorData.contacts];
            updatedContacts[contactIndex][field.split('.')[1]] = event.target.value;
            setOperatorData({ ...operatorData, contacts: updatedContacts });
        } else {
            setOperatorData({ ...operatorData, [field]: event.target.value });
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setOperatorData({ ...operatorData, file: file });
            setLogoUrl(URL.createObjectURL(file));
        }
    };

    const handleSave = () => {
        if (!operatorData || !operatorData.id) {
            console.error('Operator data is missing or does not have an ID');
            return;
        }
  
let formData = {
    id: operatorData.id,
    name: operatorData.name,
    work_from: operatorData.work_from,
    work_to: operatorData.work_to,
};

if (operatorData.file) {
   
    formData.file =  operatorData.file;
} 

// Dynamically add contacts to formData
operatorData.contacts.forEach((contact, index) => {
    formData[`contacts[${index}][id]`] = contact.id;
    formData[`contacts[${index}][phone_number]`] = contact.phone_number;
    formData[`contacts[${index}][web_site]`] = contact.web_site;
    formData[`contacts[${index}][email]`] = contact.email;
});

        
        editRentACarOperator(formData.id, formData);        
   
 
        onClose(); // Close the popup
    };
    

   
    

   


    return (
        <PopupOverlay>
            <PopupContent>
                <CloseButton onClick={onClose}>X</CloseButton>
                <Title>{operatorData.name}</Title>
                <Container>
                    <Flex>
                        <Label htmlFor="name">{t("name")}</Label>
                        <Input
                            type="text"
                            value={operatorData.name}
                            onChange={(e) => handleInputChange(e, 'name')}
                            placeholder={t("name")}
                        />
                    </Flex>
                    <Flex>
                        <Label>{t("logo")}</Label>
                        {logoUrl && <ImagePreview src={logoUrl} alt="Company Logo" />}
                        <UploadText onClick={() => document.getElementById('logoInput').click()}>
                            Upload New Logo
                        </UploadText>
                        <FileInput
                            type="file"
                            id="logoInput"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </Flex>
                 

                  <Flex>
                <Label htmlFor="name">{t("working-hours")}</Label>
                <Input
                    type="time"
                    value={operatorData.work_from}
                    onChange={(e) => handleInputChange(e, 'work_from')}
                    placeholder={t("working-from")}
                />
                <Input
                    type="time"
                    value={operatorData.work_to}
                    onChange={(e) => handleInputChange(e, 'work_to')}
                    placeholder={t("working-to")}
                />
                </Flex>
        
               
                {operatorData.contacts.map((contact, index) => (
                    <Container key={index}>
                          <Flex>
                        <Label htmlFor="name">{t("phone-number")}</Label>
                        <Input
                            type="text"
                            value={contact.phone_number}
                            onChange={(e) => handleInputChange(e, `contacts.phone_number`, index)}
                            placeholder={t("phone-number")}
                        />
                        </Flex>
                          <Flex>
                        <Label htmlFor="name">{t("website")}</Label>
                        <Input
                            type="text"
                            value={contact.web_site}
                            onChange={(e) => handleInputChange(e, `contacts.web_site`, index)}
                            placeholder={t("website")}
                        />
                          </Flex> 
                           <Flex>
                        <Label htmlFor="name">{t("email")}</Label>
                        <Input
                            type="email"
                            value={contact.email}
                            onChange={(e) => handleInputChange(e, `contacts.email`, index)}
                            placeholder={t("email")}
                        />
                        </Flex> 
                    </Container>
                    
                ))}
         </Container>
         <Button onClick={handleSave}>{t("save")}</Button>

                </PopupContent>
        </PopupOverlay>
    );
};

export default OperatorEditPopup;
