/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './ForgotPassword.css';
import logo_tav from '../../assets/images/logo-tav.png';
import { useTranslation } from 'react-i18next';
import env from '../../assets/env.js'



const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const languageOptions = ['en', 'mk', 'al'];
    const [isLanguageManuallySelected, setIsLanguageManuallySelected] = useState(false);
    const language_text = ['PLEASE SELECT YOUR PREFERRED LANGUAGE', 'ВЕ МОЛИМЕ ИЗБЕРЕТЕ ГО ВАШИОТ ЈАЗИК', 'JU LUTEM ZGJIDHNI GJUHËN TUAJ E PREFERUAR'];
    const [showForm, setShowForm] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [emailPlaceholder, setEmailPlaceholder] = useState(t('Email*'));

    useEffect(() => {
      setEmailPlaceholder(t('Email*'));
  }, [t,emailPlaceholder]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
  event.preventDefault();
  
  if (!buttonDisabled) {
    setButtonDisabled(true)
    try {
      const response = await fetch(env.API_URL+'/forgot-password/email', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': languageOptions[currentIndex],
        },
        body: JSON.stringify({
          email: email,
        })
      });
      if(response){
        
      }
      if (response) {
        setShowForm(false);
        setShowSuccessMessage(true);
        const timer = setTimeout(() => {
          navigate(`/${i18n.language}/forgot-password/code`, { state: { email: email } });
        }, 5500);
      }
    } catch (error) {
      // console.error('Error during code verification:', error);
    }finally {
      setButtonDisabled(false);
    }
  } else {
  }
};

const handleLanguageChange = (language) => {
  const newIndex = languageOptions.indexOf(language);
  if (newIndex !== -1) {
    setCurrentIndex(newIndex);
  }
  i18n.changeLanguage(language);
  setIsLanguageManuallySelected(true);
  navigate(`/${language}/forgot-password/`);
};

const getButtonVariant = (languageIndex) => {
  return currentIndex === languageIndex ? "outline-primary fw-bold opacity-100" : "outline-primary";
};

  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  useEffect(() => {
    const currentLangIndex = languageOptions.indexOf(i18n.language);
    if (currentLangIndex !== -1) {
        setCurrentIndex(currentLangIndex);
    }
}, [i18n.language]);

  return (
    <div className="forgot-password">
      <div className="container-fluid">
        <div className="row">
            <div className='col-xl-12 text-center'>
                <div className="center-logo">
                  <Link to={`/${i18n.language}/login/`}>
                      <img src={logo_tav} alt="Your Logo" />
                  </Link>  
                </div>
            </div>
        </div>
          <div id='row-parrent' className='row'>
          <div className="col-sm-6 mt-3">
            <div className="language-chooser">
            <span className='mb-3' id='selection-text-normal'>{language_text[currentIndex]}</span>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(0)} // 0 for English
                onClick={() => handleLanguageChange('en')}
              >
                ENGLISH
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(1)} // 1 for Macedonian
                onClick={() => handleLanguageChange('mk')}
              >
                MACEDONIAN
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(2)} // 2 for Albanian
                onClick={() => handleLanguageChange('al')}
              >
                ALBANIAN
              </Button>
            </div>
          </div>
          <div className="col-sm-6 vertical-line">
          {showForm && (
            <form id='form-styling' onSubmit={handleSubmit} className='mt-3'>
              <span id='forgot-txt'>{t('FORGOT YOUR PASSWORD?')}</span>
                <div className="form-group mt-4 mb-4">
                  <input
                  placeholder={emailPlaceholder}
                  type="text"
                  className="form-control rounded-0 placeholder-color"
                  id="username"
                  value={email}
                  onChange={handleEmailChange}
                  />
              </div>
              <button
                id='send-rec-link'
                type="submit"
                className="btn btn-primary rounded-0 w-100 fs-4"
                disabled={!isEmailValid() || buttonDisabled}>
                {t('SEND RECOVERY CODE')}
              </button>
              <Link className='w-100 opacity-50' id='change-color' to={`/${i18n.language}/login`}>{t('Back to LOG IN')}</Link>
           </form>
          )}
            {showSuccessMessage && (
              <div id='success-message' className="">
                <br/>
                <span>
                  <span className='fw-normal'>{t('YOUR PASSWORD RECOVERY CODE HAS BEEN SENT ON')} "{email}".</span><br/>
                  {t('If this email is in our database you will receive recovery code shortly.')}<br/>
                {t('Please check your email Inbox or Spam and view the recovery code.')}
                </span>

              </div>
          )}
          </div>  
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
