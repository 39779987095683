import React from 'react';
import styled from 'styled-components';
import carIcon from "../../assets/icons/car.svg";


const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:1rem;
`;

const Icon = styled.img`
  width: 20px; 
  height: 20px;
  margin-right: 8px; 
  @media screen and (min-width: 768px) {
    width:40px;
    height:40px;
  }

`;

const Text = styled.div`
  font-size: 1.25rem;
  color: #031F73;
  font-weight:700;
  @media screen and (min-width: 768px) {
    font-size:2rem;
  }
`;

const Title = ({ icon, text }) => {


  return (
    <TitleContainer>
      <Icon src={carIcon} alt="Icon" />
      <Text>{text.toUpperCase()}</Text>
    </TitleContainer>
  );
};

export default Title;
