/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import PaginationTest from './PaginationTest';
import { useTranslation } from 'react-i18next';
import marker_ic from '../../assets/icons/marker-ic.png'
import logout_ic from '../../assets/icons/logout-ic.png';
import env from '../../assets/env.js';

const TableCorp = ({
  handleShowEditUser,
  currentPage,
  sortOrder,
  onPageChange,
  searchQuery,
  shouldFetchData,
  resetFetchDataTrigger,
  onSearchButtonClick
}) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(env.API_URL+'/logout', {
        mode: 'cors',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        localStorage.removeItem('accessToken'); 
        localStorage.removeItem('userRole');
        localStorage.removeItem('email');

        navigate(`/en/login`);
      } else {
        console.error('Logout failed:', response.status);
        navigate(`/en/login`);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      let response;

        response = await axios.get(env.API_URL + '/dashboard/user/list', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            search: searchQuery,
            per_page: '8',
            page: currentPage.toString(),
            sort_by: 'total_time_in_last_7_days',
            order_by: sortOrder,
          },
        });
        if (response.data && response.data.data) {
          setUserData(response.data.data);
          setTotalPages(response.data.meta.last_page);
        }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  
  // fetch data on mount
  useEffect(() => {
    fetchData();
}, [currentPage, searchQuery, sortOrder]);


useEffect(() => {
  if (shouldFetchData) {
      fetchData();
      resetFetchDataTrigger();
  }
}, [shouldFetchData, resetFetchDataTrigger]);

  return (
    <>
    <div className='table-responsive mt-4'>
      <table className="table">
        <thead>
          <tr className=''>
            <th id='table-data' className='border-end' scope="col">{t('Name and Surname')}</th>
            <th id='table-data' className='border-end' scope="col">{t('Email')}</th>
            <th id='table-data' className='border-end' scope="col">{t('Role')}</th>
            <th id='table-data' className='border-end' scope="col">{t('Online in last 7 days')}</th>
            <th id='table-data'>{t('Edit User')}</th>
          </tr>
        </thead>
        <tbody id='table-body'>
          {userData.map((user) => (
            <tr key={user.id}>
              <td id='table-data' className='border-end'>{user.name} {user.surname}</td>
              <td id='table-data' className='border-end'>{user.email}</td>
              <td id='table-data' className='border-end'>{user.role.name}</td>
              <td id='table-data' className='border-end'>
                {user.total_time_in_last_7_days}
                {user.is_online ? <span className='circle'></span> : null}
              </td>
              <th id='table-data' className=' text-center' scope="col">
                <Link onClick={() => handleShowEditUser(user.id)} className='mx-1 text-decoration-none'><img src={marker_ic} id='edit-marker' alt='marker_ic'/></Link>
              </th>
            </tr>
          ))}
          <tr id='last-row'>
            <td id='table-data'></td>
            <th id='table-data'></th>
            <td id='table-data'></td>
            <td id='table-data'>{t('Active Users')} <span className='circle'></span></td>
            <td id='table-data'></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className='col-xl-12'>
    <div className='row'>
      <div className='col-sm-2'>
        <img className='img_signout' src={logout_ic} alt="signout" />
        <Link className='fw-bold' id='change-color' onClick={handleLogout}>{t('SIGN OUT')}</Link>
      </div>
      <div className='col-sm-8 justify-content-center d-flex'>
        <PaginationTest
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
      <div className='col-xs-2'>
        
      </div>
    </div>
  </div>
  </>
  );
};

export default TableCorp;
