/* eslint-disable no-unused-vars */
import React,{useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoadingScreen from './pages/sign-in/LoadingScreen.js';
import WelcomeScreen from './pages/sign-in/WelcomeScreen.js';
import LoginScreen from './pages/sign-in/LoginScreen.js';
import ForgotPassword from './pages/sign-in/ForgotPassword.js';
import IndexScreen from './pages/IndexScreen.js';
import ManageCorporateUsers from './pages/ManageCorporateUsers.js';
import Missing from './components/auth/Missing.js';
import i18n from './components/data/i18n.js';
import ProtectedRoute from './components/auth/ProtectedRoutes.js'; 
import ForgotPasswordCode from './pages/sign-in/ForgotPasswordCode.js';
import ChangePassword from './pages/sign-in/ChangePassword.js';
import env from './assets/env.js';
import Dashboard from './pages/manage-landing/Dashboard.js';
import CarPark from './pages/manage-landing/CarPark';
import RentACar from './pages/manage-landing/RentACar';
import Taxi from './pages/manage-landing/Taxi';
import Bus from "./pages/manage-landing/Bus.js";

function App() {

  const getExpiryTime = (token) => {
    return 25 * 60 * 1000; 
  };

  const handleRefreshToken = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return;

    try {
      const response = await fetch(env.API_URL + '/refresh', {
        mode: 'cors',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        const { authorization } = data;
        const { access_token } = authorization;
        localStorage.setItem('accessToken', access_token);
        // console.log(data);
        setTimeout(handleRefreshToken, getExpiryTime(access_token));
      } else {
        console.error('Failed to refresh token, status code:', response.status);
        handleLoginRedirect();
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      handleLoginRedirect();
    }
  };

  const handleLoginRedirect = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('email');
    window.location.href = `/${i18n.language}/login`;
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setTimeout(handleRefreshToken, getExpiryTime(token));
    }
  }, []);


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate replace to={`/${i18n.language}/loading`} />} />

        {['en', 'mk', 'al'].map((lang) => (
          <Route key={lang} path={`/${lang}/*`} element={
            <Routes>
              <Route path="loading" element={<LoadingScreen />} />
              <Route path="welcome" element={<WelcomeScreen />} />
              <Route path="login" element={<LoginScreen />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="forgot-password/code" element={<ForgotPasswordCode />} />
              <Route path="forgot-password/code/change-password" element={<ChangePassword />} />
            
                           
              <Route path="index" element={
                <ProtectedRoute allowedRoles={[1, 2]}>
                  <IndexScreen />
                </ProtectedRoute>
              } />
              <Route path="index/manage-landing-page" element={
                <ProtectedRoute allowedRoles={[1, 2]}>
                    <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="index/manage-landing-page/taxi" element={
                <ProtectedRoute allowedRoles={[1, 2]}>
                  <Taxi />
                </ProtectedRoute>
              } />
               <Route path="index/manage-landing-page/bus" element={
                <ProtectedRoute allowedRoles={[1, 2]}>
                  <Bus />
                </ProtectedRoute>
              } />
              <Route path="index/manage-landing-page/rent-a-car" element={
                <ProtectedRoute allowedRoles={[1, 2]}>
                 <RentACar />
                </ProtectedRoute>
              } />
              <Route path="index/manage-landing-page/car-park" element={
                <ProtectedRoute allowedRoles={[1, 2]}>
<CarPark />                </ProtectedRoute>
              } />



              {/* Example of a protected route */}
              <Route path="index/manage-corporate" element={
                <ProtectedRoute allowedRoles={[1]}>
                  <ManageCorporateUsers />
                </ProtectedRoute>
              } />
              <Route path="*" element={<Missing />} />
            </Routes>
          }/>
        ))}
      </Routes>
    </Router>
  );
}

export default App;
