import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './Pagination.css';

function PaginationTest({ currentPage, totalPages, onPageChange }) {
    const paginationItemStyle = {
        width: '39px',
        height: '39px',
        margin: '0 5px', 
    };

    const generatePaginationItems = () => {
        const items = [];

        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={paginationItemStyle}
                className='rounded-0 '
            />
        );

        for (let i = 1; i <= totalPages; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                    style={paginationItemStyle}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={paginationItemStyle}
            />
        );

        return items;
    };

    return <Pagination>{generatePaginationItems()}</Pagination>;
}

export default PaginationTest;
