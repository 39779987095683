import React, { useState, useEffect  } from 'react';
import { Link} from 'react-router-dom';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import env from '../../assets/env.js'

const EditUserModal = ({
  show,
  profileData,
  handleCloseEditUser,
}) => {
  const [formData, setFormData] = useState(profileData);
  const { t,i18n } = useTranslation(); 
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false); 
  const [initialProfile, setInitialProfile] = useState({});

  useEffect(() => {
    setFormData({ ...profileData });
    setInitialProfile(profileData)
  }, [profileData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errorMessages, setErrorMessages] = useState({
    name: '',
    surname: '',
  });

  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('accessToken');
      const userId = formData.id;
        const response = await fetch(env.API_URL+`/dashboard/user/edit/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`,
           'Accept-Language': i18n.language
        },
        body: JSON.stringify({
          name: formData.name,
          surname: formData.surname,
        }),
      });
  
      if (response.status === 200) {
        const data = await response.json();
        setErrorMessages({ name: '', surname: ''});
        setEditSuccess(true);
      } else {
        const error = await response.json();
        if (error && error.error && error.error.message) {
          const parsedError = JSON.parse(error.error.message);
          setErrorMessages({
            name: parsedError.name ? parsedError.name[0] : '',
            surname: parsedError.surname ? parsedError.surname[0] : '',
          });
        }
        throw new Error('Failed to edit user');
      }
    } catch (error) {
      console.error('Error editing user:', error);
    }
  };
  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteUser()
  };
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleDeleteUser = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const userId = formData.id;

      const response = await fetch(env.API_URL+`/dashboard/user/delete/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Language': i18n.language
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        // console.log('User deleted:', data);
        const emailUser = localStorage.getItem('email');
        if(formData.email === emailUser){
          localStorage.removeItem('accessToken'); 
          localStorage.removeItem('userRole');
          localStorage.removeItem('email');  
          window.location.href = '/en/login';
        }
        setDeleteSuccess(true);
        const timer = setInterval(() => {
          window.location.reload();
        }, 3000);

      } else {
        alert('Failed to delete user. Please try again.');
        setDeleteSuccess(false);
        setShowDeleteConfirmation(false);
        setDeleteSuccess(false);  
        handleCloseEditUser(); 
        throw new Error('Failed to delete user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  const isProfileEdited = () => {
    return formData.name !== initialProfile.name ||
    formData.surname !== initialProfile.surname;
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        setDeleteSuccess(false);
        setShowDeleteConfirmation(false);
        setEditSuccess(false);
        setErrorMessages({ name: '', surname: ''});
        handleCloseEditUser();
      }} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
      <Modal.Header closeButton>
      </Modal.Header>
      {!showDeleteConfirmation ? (
      <Modal.Body>
        {editSuccess ? (
              <p className='fw-bold' id='change-color'>{t('You have successfully edited profile!')}</p>
        ) : (
          <>
        <div className='row'>
          <div className='col-xl-6'>
            <h5 id='change-color'>{t('EDIT USER')}</h5>
          </div>
          <div className='col-xl-6 text-end'>
            <Link className='border-0 text-danger' onClick={handleDelete}>
              {t('Delete User')}
            </Link>
          </div>
        </div>
        <Form onSubmit={handleEditUser} className='form-group'>
          <Row className="mb-3">
            <Form.Group id='change-color' controlId="formName" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3}>{t('Name')}</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className='rounded-0'
                />
              {errorMessages.name && <div className="text-danger">{errorMessages.name}</div>}
              </Col>
            </Form.Group>
          </Row>

          <Row className="mb-3">
          <Form.Group id='change-color' controlId="formSurname" as={Col} className="d-flex align-items-center">
            <Form.Label column sm={3}>{t('Surname')}</Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleInputChange} 
                className='rounded-0'
              />
            {errorMessages.surname && <div className="text-danger">{errorMessages.surname}</div>}
            </Col>
          </Form.Group>
        </Row>

          <Row className="mb-3">
            <Form.Group id='change-color' controlId="formEmail" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3}>{t('Email')}</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="email"
                  name="email"
                  value={profileData.email}
                  onChange={handleInputChange}
                  className='rounded-0'
                  disabled
                />
              </Col>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group id='change-color' controlId="formUserType" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3}>{t('User Type')}</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type='text'
                  name="userType"
                  value={profileData.role?.name || ''}
                  onChange={handleInputChange}
                  className='rounded-0'
                  disabled
                >
                </Form.Control>
              </Col>
            </Form.Group>
          </Row>

          <Row className='mt-4'>
            <Col sm={12} className='text-center'>
              <Button disabled={!isProfileEdited()} variant="btn btn-primary rounded-0 fs-3 px-5" id='login-button' type="submit" className='rounded-0'>
                {t('Edit User')}
              </Button>
              <Button id='change-color' variant="btn mx-4" onClick={handleCloseEditUser} className='rounded-0 text-decoration-underline border-0 '>
                {t('Cancel')}
              </Button>
            </Col>
          </Row>
        </Form>
       </>
        )}
      </Modal.Body>
      ) : (
        <div className="text-center p-4">
            {deleteSuccess ? (
              <p className='text-danger'>User {formData.name} {formData.surname} was successfully deleted.</p>
            ) : (
              <div className="text-center p-3">
                <h5 id='change-color'>You are about to delete {formData.name} {formData.surname}</h5>
                <Button className='opacity-75' variant="btn btn-danger rounded-0 fs-3 w-50 mt-3" onClick={handleConfirmDelete}>
                  {t('Confirm')}
                </Button>{' '}
                <Button id='change-color' variant="btn mx-3 mt-3" onClick={handleCancelDelete} className='rounded-0 text-decoration-underline border-0 '>
                  {t('Back')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditUserModal;
