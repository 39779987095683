import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation,Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './ForgotPassword.css';
import logo_tav from '../../assets/images/logo-tav.png';
import { useTranslation } from 'react-i18next';
import env from '../../assets/env.js'

const ForgotPasswordCode = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState(location.state?.email);
    const {  t,i18n } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const languageOptions = ['en', 'mk', 'al'];
    const language_text = ['PLEASE SELECT YOUR PREFERRED LANGUAGE', 'ВЕ МОЛИМЕ ИЗБЕРЕТЕ ГО ВАШИОТ ЈАЗИК', 'JU LUTEM ZGJIDHNI GJUHËN TUAJ E PREFERUAR'];
    const [isLanguageManuallySelected, setIsLanguageManuallySelected] = useState(false);
    const [recoveryCode, setRecoveryCode] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [codePlaceholder, setCodePlaceholder] = useState(t('CODE* (123456)'));

    useEffect(() => {
      setCodePlaceholder(t('CODE* (123456)'));
  }, [t,codePlaceholder]);

    useEffect(() => {
      const currentLangIndex = languageOptions.indexOf(i18n.language);
      if (currentLangIndex !== -1) {
          setCurrentIndex(currentLangIndex);
      }
  }, [i18n.language]);

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const emailParam = searchParams.get('email');
      if (emailParam) {
        setEmail(emailParam);
      }
    }, [location.search]);

  const isValidCode = (code) => {
    const codeRegex = /^[a-zA-Z0-9]{6}$/;
    return codeRegex.test(code);
  };
  const handleCodeChange = (event) => {
    setRecoveryCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (isValidCode(recoveryCode)) {
      try {
        const response = await fetch(env.API_URL+'/forgot-password/code/check', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': languageOptions[currentIndex],
          },
          body: JSON.stringify({
            code: recoveryCode,
          }),
        });
  
        if (response.status === 200) {
          console.log('Code verification successful');
          navigate(`/${i18n.language}/forgot-password/code/change-password`, { state: { email: email, code:recoveryCode } });
          } else {
          const error = await response.json();
          setErrMsg(JSON.parse(error.error.message).code[0]);
          setRecoveryCode('');
        }
      } catch (error) {
        console.error('Error during code verification:', error);
      }
    } else {
      // Handle invalid code
      console.error('Invalid code format');
    }
  };

  const handleLanguageChange = (language) => {
    const newIndex = languageOptions.indexOf(language);
    if (newIndex !== -1) {
      setCurrentIndex(newIndex);
    }
    i18n.changeLanguage(language);
    setIsLanguageManuallySelected(true);
    navigate(`/${language}/forgot-password/code/`);
  };
  const getButtonVariant = (languageIndex) => {
    return currentIndex === languageIndex ? "outline-primary fw-bold opacity-100" : "outline-primary";
  };
  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="forgot-password">
      <div className="container-fluid">
        <div className="row">
            <div className='col-xl-12 text-center'>
                <div className="center-logo">
                  <Link to={`/${i18n.language}/login/`}>
                      <img src={logo_tav} alt="Your Logo" />
                  </Link>           
                 </div>
            </div>
        </div>
          <div id='row-parrent' className='row'>
          <div className="col-sm-6 mt-3">
            <div className="language-chooser">
            <span className='mb-3' id='selection-text-normal'>{language_text[currentIndex]}</span>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(0)} // 0 for English
                onClick={() => handleLanguageChange('en')}
              >
                ENGLISH
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(1)} // 1 for Macedonian
                onClick={() => handleLanguageChange('mk')}
              >
                MACEDONIAN
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(2)} // 2 for Albanian
                onClick={() => handleLanguageChange('al')}
              >
                ALBANIAN
              </Button>
            </div>
          </div>
          <div className="col-sm-6 vertical-line">
          <form id='form-styling' onSubmit={handleSubmit} className='mt-3'>
            <span id='forgot-txt'>{t('ENTER YOUR PASSWORD RECOVERY CODE')}</span>
              <div className="form-group mt-4 mb-4">
                <input
                placeholder='E-mail*'
                type="text"
                className="form-control rounded-0"
                id="username"
                value={email}
                disabled
                />
            </div>
            <div className="form-group mt-4 mb-4">
                <input
                placeholder={codePlaceholder}
                type="text"
                className="form-control rounded-0 placeholder-color"
                id="code"
                value={recoveryCode}
                onChange={handleCodeChange}        
                maxLength={6}   
                autoFocus
                />
               {errMsg && <p className="error-message text-danger">{errMsg}</p>}
            </div>
            <button
              id='send-rec-link'
              type="submit"
              className="btn btn-primary rounded-0 w-100 fs-4 mb-2"
              disabled={!isValidCode(recoveryCode)}>
              {t('CHECK CODE')}
            </button>
            <Link className='w-100 opacity-50' id='change-color' to={`/${i18n.language}/login`}>{t('Back to LOG IN')}</Link>
        </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordCode;
