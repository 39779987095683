import React,{useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import { useNavigate,Link } from 'react-router-dom';
import logo from "../../assets/images/logo-tav.png";
import homeIc from "../../assets/icons/home-ic.png";
import signOutIc from "../../assets/icons/logout-ic.png";
import manageIc from "../../assets/icons/manage-cor-ic.png";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import env from '../../assets/env.js';

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  height: 100vh;
  padding:2.5rem 3rem 2.5rem 3rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  

  & > * {
    flex: 1; 
  }
`;
const Footer = styled.footer`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`;

const LinksWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Logo = styled.img`
  display: block;
  max-width: 32vh; 
  height: auto; 
  margin: 0 auto;
`;



const LanguageSelector = styled.div`
display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;

const Icon =styled.img`
height:1.75rem;
width:1.75rem;
margin-right:0.25rem;`
const StyledLink=styled(Link)`
margin-right:1.25rem;
display:flex;
align-items:center;


`
const Container = ({ children }) => {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation();

  useEffect(() => {
    document.title = 'Tav Index Dashboard';
  }, []);  
 const languageNames = {
  en: 'EN',
  mk: 'MK',
  al: 'AL',
};

const getCurrentLanguageName = () => {
  return languageNames[i18n.language] || 'Language';
};
  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(env.API_URL+'/logout', {
        mode: 'cors',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        localStorage.removeItem('accessToken'); 
        localStorage.removeItem('userRole');
        localStorage.removeItem('email');

        navigate(`/en/login`);
      } else {
        console.error('Logout failed:', response.status);
        navigate(`/en/login`);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
      .then(() => {
        console.log(`Language changed to: ${lng}`);
      })
      .catch((error) => {
        console.error('Error while changing language:', error);
      });
  };
  return (
    <ContainerWrapper>
      <Header>
   
        <LinksWrapper>
          <StyledLink id='change-color' className='fw-bold' to={`/${i18n.language}/index`}>
            <Icon src={homeIc} />
            <span>{t('Home')}</span>
          </StyledLink>
          <StyledLink id='change-color' className='fw-bold' to={`/${i18n.language}/index/manage-corporate`}>
            <Icon src={manageIc} />
            <span>{t('Manage corporate users')}</span>
          </StyledLink>
        </LinksWrapper>
        <Logo src={logo} />
       
    <LanguageSelector>
    <DropdownButton title={`${getCurrentLanguageName()}↓`} variant="btn fs-5 mx-2" id="dropdown-basic">
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('mk')}>Macedonian</Dropdown.Item>
                <Dropdown.Item id='dropdown-item' onClick={() => changeLanguage('al')}>Albanian</Dropdown.Item>
              </DropdownButton>
    </LanguageSelector>
        
      </Header>
      {children}
      <Footer>
      <StyledLink className='fw-bold' id='change-color' onClick={handleLogout}><Icon src={signOutIc}></Icon>{t('SIGN OUT')}</StyledLink> 
      </Footer>
    </ContainerWrapper>
  );
};

export default Container;
