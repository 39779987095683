import React, { useState } from 'react';
import styled from 'styled-components';
import { storeRentACarOperator } from '../../services/rentACarServices';
import { useTranslation } from 'react-i18next';

// Styled components
const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; 
    z-index: 1000;
`;

const PopupContent = styled.div`
    background: white;
    padding: 2rem 3rem;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    width: 50%;
    z-index: 1001;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    color: #031F73;
`;

const CloseButton = styled.div`
    float: right;
    color: #031F73;
    cursor: pointer;
`;

const Label = styled.div`
    color: #031F73;
    min-width: 20%;
    font-weight: 600;
`;

const Flex = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    border-bottom: 1px solid rgba(3, 31, 115, 0.1);
`;

const Input = styled.input`
    border: none;
    padding: 0.75rem 1rem;
    color: #031F73;
    &:focus {
        background-color: rgba(3, 31, 115, 0.1);
    }
    border-left: 1px solid rgba(3, 31, 115, 0.1);
    outline: none;
    cursor: pointer;
    width: 100%;
`;

const Button = styled.button`
    background-color: #031F73;
    color: white;
    padding: 0.5rem 2.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    border: none;
    margin-top: 2rem;
    cursor: pointer
    `;
    

const OperatorAddPopup = ({ onClose, onSave }) => {
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState(false);

    const initialContacts = [
        { phone_number: '', web_site: '', email: '' },
        { phone_number: '', web_site: '', email: '' }
    ];

    const [operatorData, setOperatorData] = useState({
        name: '',
        file: null,
        work_from: '',
        work_to: '',
        contacts: initialContacts
    });

    const handleInputChange = (event, field, contactIndex) => {
        if (field === 'file') { // Changed to 'file'
            const file = event.target.files[0];
            console.log("Selected file:", file);
    
            setOperatorData(prevState => ({
                ...prevState,
                file: file // Changed to 'file'
            }));
        
        } else if (field.includes('contacts')) {
            const newContacts = operatorData.contacts.map((contact, index) => {
                if (index === contactIndex) {
                    return { ...contact, [field.split('.')[1]]: event.target.value };
                }
                return contact;
            });
            setOperatorData({ ...operatorData, contacts: newContacts });
        } else {
            setOperatorData({ ...operatorData, [field]: event.target.value });
        }
    };

    const handleSave = async (event) => {
        if (isSaving) return;
    setIsSaving(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', operatorData.name);
        if (operatorData.file) {
            formData.append('file', operatorData.file); // Changed to 'file'
        }
        formData.append('work_from', operatorData.work_from);
        formData.append('work_to', operatorData.work_to);
        operatorData.contacts.forEach((contact, index) => {
            formData.append(`contacts[${index}][phone_number]`, contact.phone_number);
            formData.append(`contacts[${index}][web_site]`, contact.web_site);
            formData.append(`contacts[${index}][email]`, contact.email);
        });

        onSave(formData);
        setIsSaving(false);
    };

    return (
        <PopupOverlay>
            <PopupContent>
                <CloseButton onClick={onClose}>X</CloseButton>
                <Title>{t("add-new-operator")}</Title>
                <Container>
                  
                    <Flex>
                        <Label htmlFor="name">{t("name")}</Label>
                        <Input
                            type="text"
                            value={operatorData.name}
                            onChange={(e) => handleInputChange(e, 'name')}
                            placeholder="Name"
                        />
                    </Flex>
                    <Flex>
                <Label htmlFor="file">{t("logo")}</Label>
                <Input
                    type="file"
                    onChange={(e) => handleInputChange(e, 'file')}
                    placeholder="Company Logo"
                />
            </Flex>
                    <Flex>
                        <Label htmlFor="work_from">{t("working-hours-from")}</Label>
                        <Input
                            type="time"
                            value={operatorData.work_from}
                            onChange={(e) => handleInputChange(e, 'work_from')}
                            placeholder={t("working-from")}
                        />
                        <Label htmlFor="work_to">{t("working-hours-to")}</Label>
                        <Input
                            type="time"
                            value={operatorData.work_to}
                            onChange={(e) => handleInputChange(e, 'work_to')}
                            placeholder={t("working-to")}
                        />
                    </Flex>
                   
                    {operatorData.contacts.map((contact, index) => (
                        <Container key={index}>
                            <Flex>
                                <Label htmlFor={`phone_number_${index}`}>{t(`phone-number-${index+1}`)}</Label>
                                <Input
                                    type="text"
                                    value={contact.phone_number}
                                    onChange={(e) => handleInputChange(e, 'contacts.phone_number', index)}
                                    placeholder={t("phone-number")}
                                />
                            </Flex>
                            <Flex>
                                <Label htmlFor={`web_site_${index}`}>{t(`website-${index+1}`)}</Label>
                                <Input
                                    type="text"
                                    value={contact.web_site}
                                    onChange={(e) => handleInputChange(e, 'contacts.web_site', index)}
                                    placeholder={t("website")}
                                />
                            </Flex>
                            <Flex>
                                <Label htmlFor={`email_${index}`}>{t(`email-${index+1}`)}</Label>
                                <Input
                                    type="email"
                                    value={contact.email}
                                    onChange={(e) => handleInputChange(e, 'contacts.email', index)}
                                    placeholder={t("email")}
                                />
                            </Flex>
                        </Container>
                    ))}
                   <Button onClick={(e) => handleSave(e)}>{t("add-operator")}</Button>

                </Container>
            </PopupContent>
        </PopupOverlay>
    );
};

export default OperatorAddPopup;
