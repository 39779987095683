import axios from 'axios';
import i18n from 'i18next';
import {
    DASHBOARD_CAR_PARK_PARKING_LIST,
    DASHBOARD_CAR_PARK_PARKING_STORE,
    DASHBOARD_CAR_PARK_PARKING_DELETE,
    DASHBOARD_CAR_PARK_PARKING_EDIT,
    DASHBOARD_CAR_PARK_HUG_AND_FLY_LIST,
    DASHBOARD_CAR_PARK_HUG_AND_FLY_STORE,
    DASHBOARD_CAR_PARK_HUG_AND_FLY_DELETE,
    DASHBOARD_CAR_PARK_HUG_AND_FLY_EDIT,
    DASHBOARD_CAR_PARK_SUBSCRIPTION_LIST,
    DASHBOARD_CAR_PARK_SUBSCRIPTION_STORE,
    DASHBOARD_CAR_PARK_SUBSCRIPTION_DELETE,
    DASHBOARD_CAR_PARK_SUBSCRIPTION_EDIT
} from './endpoints';

const getCurrentLanguage = () => i18n.language;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const currentLanguage = getCurrentLanguage();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['Accept-Language'] = currentLanguage; 
  return config;
});

const token = localStorage.getItem('accessToken');

const config = {
    headers: { 
      Authorization: `Bearer ${token}`,
      'Accept-Language': getCurrentLanguage() 
    }
  };
// Parking services
const parking = {
    list: () => axios.get(DASHBOARD_CAR_PARK_PARKING_LIST, config),
    store: (data) => axios.post(DASHBOARD_CAR_PARK_PARKING_STORE, data, config),
    delete: (id) => axios.delete(`${DASHBOARD_CAR_PARK_PARKING_DELETE.replace('%(id)s', id)}`, config),
    edit: (id, data) => axios.put(`${DASHBOARD_CAR_PARK_PARKING_EDIT.replace('%(id)s', id)}`, data, config),
};

// Hug & Fly services
const hugAndFly = {
    list: () => axios.get(DASHBOARD_CAR_PARK_HUG_AND_FLY_LIST, config),
    store: (data) => axios.post(DASHBOARD_CAR_PARK_HUG_AND_FLY_STORE, data, config),
    delete: (id) => axios.delete(`${DASHBOARD_CAR_PARK_HUG_AND_FLY_DELETE.replace('%(id)s', id)}`, config),
    edit: (id, data) => axios.put(`${DASHBOARD_CAR_PARK_HUG_AND_FLY_EDIT.replace('%(id)s', id)}`, data, config),
};

// Subscription services
const subscription = {
    list: () => axios.get(DASHBOARD_CAR_PARK_SUBSCRIPTION_LIST, config),
    store: (data) => axios.post(DASHBOARD_CAR_PARK_SUBSCRIPTION_STORE, data, config),
    delete: (id) => axios.delete(`${DASHBOARD_CAR_PARK_SUBSCRIPTION_DELETE.replace('%(id)s', id)}`, config),
    edit: (id, data) => axios.put(`${DASHBOARD_CAR_PARK_SUBSCRIPTION_EDIT.replace('%(id)s', id)}`, data, config),
};

export default { parking, hugAndFly, subscription };
