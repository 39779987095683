import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const userRole = localStorage.getItem('userRole');
  const location = useLocation();
  const languagePrefix = location.pathname.split('/')[1]; 

  const token = localStorage.getItem('accessToken');

  if (!token) {
    return <Navigate to={`/${languagePrefix}/login`} />;
  }
  
  if (!allowedRoles.includes(parseInt(userRole))) {
    return <Navigate to={`/${languagePrefix}/index`} />;
  }

  return children;
};

export default ProtectedRoute;
