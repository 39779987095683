import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../../components/manage-landing-components/Container';
import Title from '../../components/manage-landing-components/Title';
import styled from 'styled-components';
import GlobalPhotoUpload from "../../components/manage-landing-components/GlobalPhotoUpload";
import mapPhoto from "../../assets/images/map.svg";
import TaxiTable from "../../components/manage-landing-components/TaxiTable";
import { useTranslation, withTranslation } from 'react-i18next';

const BackButton = styled(Link)`
  color: #031F73; /* You can choose the color you want for the link text */
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center; 
  margin-bottom: 0.5rem; 
`;

const ArrowIcon = styled.span`
  font-size: 0.75rem;
`;

const Divider = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

`;

const Table = styled.div`
  flex: 2; 
`;

const Map = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: #031F73;

`;


const Taxi = () => {
  const { t,i18n } = useTranslation();
  return (
    <Container>
      <BackButton to={`/${i18n.language}/index/manage-landing-page`}>
        <ArrowIcon>←</ArrowIcon>{t("back")}
      </BackButton>
      <Title text={t("taxi")}/>
      <Divider>
        <Table>
          <TaxiTable />
        </Table>
        <Map>
        <GlobalPhotoUpload type_name="taxi" />
        </Map>
      </Divider>
    </Container>
  );
};

export default Taxi;
