import React, { useState, useEffect } from 'react';
import carParkServices from '../../services/carParkServices';
import checkmarkIcon from "../../assets/icons/accept.svg";
import cancelIcon from "../../assets/icons/cancel.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import { useTranslation } from 'react-i18next';
import { Container, Table, Button, AddButton, Input, TableRow, TableHead, AddRow, TableData } from "../../components/manage-landing-components/Table";
import Notification from './Notification';

const CarParkingTable = () => {
    const { t,i18n } = useTranslation();
    const [parkingOptions, setParkingOptions] = useState([]);
    const [adding, setAdding] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [recentlyUpdated, setRecentlyUpdated] = useState(null);
    const [recentlyAdded, setRecentlyAdded] = useState(false);
    const [currentOption, setCurrentOption] = useState({ quantity: '', time_quantity_type: '', price: '' });
    const [notification, setNotification] = useState({ show: false, message: '' });

    useEffect(() => {
        fetchParkingOptions();
    }, [i18n.language]);

    const fetchParkingOptions = async () => {
        try {
            const response = await carParkServices.parking.list();
            setParkingOptions(response.data.data);
            if (recentlyAdded) {
                setRecentlyAdded(false);
            }
        } catch (error) {
            console.error('Error fetching parking data:', error);
        }
    };

    const handleSave = async () => {
        try {
            let response;
            if (adding) {
                response = await carParkServices.parking.store(currentOption);
                setParkingOptions([response.data.data, ...parkingOptions]);
                setRecentlyAdded(true);
                setTimeout(() => setRecentlyAdded(false), 5000); 
            } else if (editMode) {
                response = await carParkServices.parking.edit(editingId, currentOption);
                setParkingOptions(parkingOptions.map(option => option.id === editingId ? response.data.data : option));
                setRecentlyUpdated(editingId);
                setTimeout(() => setRecentlyUpdated(null), 5000); 
            }
            setAdding(false);
            setEditMode(false);
            setCurrentOption({ quantity: '', time_quantity_type: '', price: '' });
            setNotification({ show: true, message: 'Operation successful' });
        } catch (error) {
            console.error('Error:', error);
            setNotification({ show: true, message: 'Operation failed' });
        }
    };
    

    const handleEditStart = (option) => {
        setEditingId(option.id);
        setEditMode(true);
        setCurrentOption({
            quantity: option.quantity, 
            time_quantity_type: option.quantity_type.time, 
            price: option.price
        });
    };

    const handleCancelEdit = () => {
        setEditMode(false);
        setEditingId(null);
        setCurrentOption({ quantity: '', time_quantity_type: '', price: '' });
    };

    const handleDelete = async (id) => {
        try {
            await carParkServices.parking.delete(id);
            setParkingOptions(parkingOptions.filter(option => option.id !== id));
            setNotification({ show: true, message: 'Parking option deleted' });
        } catch (error) {
            console.error('Error deleting parking option:', error);
            setNotification({ show: true, message: 'Failed to delete parking option' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentOption(prev => ({ ...prev, [name]: value }));
    };

    return (
        <Container>
            <Table>
                <thead>
                    <TableRow>
                        <TableHead>{t("quantity")}</TableHead>
                        <TableHead>{t("hours")}</TableHead>
                        <TableHead>{t("days")}</TableHead>
                        <TableHead>{t("price")}</TableHead>
                        <TableHead>{t("actions")}</TableHead>
                    </TableRow>
                </thead>
                <tbody>
                    {/* Add New Option Row */}
                    {!adding  && (
                        <AddRow>
                            <td colSpan="5">
                                <AddButton onClick={() => setAdding(true)}>{t("add-new-option")}</AddButton>
                            </td>
                        </AddRow>
                    )}

                    {/* Adding New Option */}
                    {adding && (
                        <TableRow>
                            <TableData>
                                <Input 
                                    type="text" 
                                    placeholder={t("quantity")}
                                    name="quantity" 
                                    onChange={handleChange} 
                                />
                            </TableData>
                            <TableData>
                                <Input 
                                    type="radio" 
                                    name="time_quantity_type" 
                                    value="hours" 
                                    onChange={handleChange} 
                                /> {t("hours")}
                            </TableData>
                            <TableData>
                                <Input 
                                    type="radio" 
                                    name="time_quantity_type" 
                                    value="days" 
                                    onChange={handleChange} 
                                /> {t("days")}
                            </TableData>
                            <TableData>
                                <Input 
                                    type="text" 
                                    placeholder={t("price")}
                                    name="price" 
                                    onChange={handleChange} 
                                />
                            </TableData>
                            <TableData>
                                <Button onClick={handleSave}><img src={checkmarkIcon} alt="Save" /></Button>
                                <Button onClick={() => { setAdding(false); setCurrentOption({ quantity: '', time_quantity_type: 'hours', price: '' }); }}><img src={cancelIcon} alt="Cancel" /></Button>
                            </TableData>
                        </TableRow>
                    )}

                    {/* Displaying Existing Options */}
                    {parkingOptions.map((option,index) => (
                        <TableRow key={option.id}
                        $isEditing={editMode && editingId === option.id} 
        $isRecentlyUpdated={recentlyAdded && index === 0 || recentlyUpdated === option.id}>
                            {editMode && editingId === option.id ? (
                                // Editing input fields
                                <>
                                    <TableData>
                                        <Input 
                                            type="text" 
                                            name="quantity" 
                                            value={currentOption.quantity} 
                                            onChange={handleChange} 
                                        />
                                    </TableData>
                                    <TableData>
                                        <Input 
                                            type="radio" 
                                            name="time_quantity_type" 
                                            value="hours" 
                                            checked={currentOption.time_quantity_type === 'hours'} 

                                            onChange={handleChange} 
                                        /> {t("hours")}
                                    </TableData>
                                    <TableData>
                                        <Input 
                                            type="radio" 
                                            name="time_quantity_type" 
                                            value="days" 
                                            checked={currentOption.time_quantity_type === 'days'} 
                                            onChange={handleChange} 
                                        /> {t("days")}
                                    </TableData>
                                    <TableData>
                                        <Input 
                                            type="text" 
                                            name="price" 
                                            value={currentOption.price} 
                                            onChange={handleChange} 
                                        />
                                    </TableData>
                                    <TableData>
                                        <Button onClick={handleSave}><img src={checkmarkIcon} alt="Save" /></Button>
                                        <Button onClick={handleCancelEdit}><img src={cancelIcon} alt="Cancel" /></Button>
                                    </TableData>
                                </>
                            ) : (
                                // Normal display fields
                                <>
                                    <TableData>{option.quantity}</TableData>
                                    <TableData>{option.quantity_type.time === 'hours' ? <img src={checkmarkIcon} alt="Checkmark" /> : ''}</TableData>
                                <TableData>{option.quantity_type.time === 'days' ? <img src={checkmarkIcon} alt="Checkmark" /> : ''}</TableData>                                    <TableData>{option.price}</TableData>
                                    <TableData>
                                        <Button onClick={() => handleEditStart(option)}><img src={editIcon} alt="Edit" /></Button>
                                        <Button onClick={() => handleDelete(option.id)}><img src={deleteIcon} alt="Delete" /></Button>
                                    </TableData>
                                </>
                            )}
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            {notification.show && <Notification text={notification.message} />}
        </Container>
    );
};

export default CarParkingTable;
