import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dashboardSettings from "../../services/settingsService";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';


const Container = styled.div`
  margin-top: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  height: 3rem;
  border: 1px solid #031F73;
  padding-left: 2rem;
  color: #031F73;
`;

const Flex = styled.div`
color: #031F73;
  display: flex;
  flex-direction:column;
  gap:0.5rem;
  justify-content: space-between;
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 0.5rem;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #031F7350;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #031F73;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #031F73;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

const LanguageTabsComponent = ({ keyName }) => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [showOnLandingPage, setShowOnLandingPage] = useState(false);
  const [textData, setTextData] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dashboardSettings.get(`${keyName}_text_${currentLanguage}`);
        const data = response.data;
        setShowOnLandingPage(data.value === '1');
        setTextData(data.description);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    
    const handleLanguageChange = (lng) => {
      setCurrentLanguage(lng);
      fetchData();
    };

    i18n.on('languageChanged', handleLanguageChange);


    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [currentLanguage]);

  const handleToggleChange = async () => {
    const newValue = !showOnLandingPage;
    setShowOnLandingPage(newValue);
    await publishChanges(newValue);
  };

  const handleTextChange = async (e) => {
    const updatedText = e.target.value;
    setTextData(updatedText);
    await publishChanges(showOnLandingPage, updatedText);
  };

  const publishChanges = async (updatedShowValue, updatedText = textData) => {
    try {
      const payload = {
        value: updatedShowValue ? '1' : '0',
        description: updatedText
      };
      await dashboardSettings.publish(`${keyName}_text_${currentLanguage}`, payload);
     
    } catch (error) {
      console.error('Error publishing data:', error);
    }
  };

  return (
    <Container>
      <Flex>
        <div>
        {t('landingPageInfo')}
          <SwitchLabel>
            <Checkbox
              type="checkbox"
              checked={showOnLandingPage}
              onChange={handleToggleChange}
            />
            <Slider />
          </SwitchLabel>
        </div>
        <div>
          <Input
            type="text"
            value={textData}
            onChange={handleTextChange}
            placeholder="Enter text"
          />
        </div>
      </Flex>
    </Container>
  );
};

export default LanguageTabsComponent;

