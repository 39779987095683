import React, { useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import env from '../../assets/env.js'

const AddUserModal = ({
  show,
  handleClose,
}) => {
  const { t,i18n } = useTranslation(); 
  const [addSuccess, setAddSuccess] = useState(false); 
  
  const [profileData, setProfileData] = useState({
    name: '',
    surname: '',
    email: '',
    temporary_password: '',
    user_type_id: '',
  });

  const [errorMessages, setErrorMessages] = useState({
    name: '',
    surname: '',
    email: '',
    temporary_password: '',
    user_type_id: ''
  });

  const handleModalClose = () => {
    setAddSuccess(false);
    handleClose();
    setErrorMessages({ name: '', surname: '', email: '', temporary_password: '', user_type_id: '' });
    setProfileData({ name: '', surname: '', email: '', temporary_password: '', user_type_id: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: name === 'user_type_id' ? parseInt(value) : value,
      temporary_password: name === 'temporary_password' ? value : prevProfileData.temporary_password,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('accessToken');
      // console.log(profileData);
      const response = await fetch(env.API_URL+'/dashboard/user/store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Language': i18n.language
        },
        body: JSON.stringify({
          name: profileData.name,
          surname: profileData.surname,
          email: profileData.email,
          temporary_password: profileData.temporary_password,
          user_type_id: profileData.user_type_id,
        }),
      });
  
      if (response.status === 200) {
        setErrorMessages({ name: '', surname: '', email: '', temporary_password: '', user_type_id: '' });
        setAddSuccess(true);
      } else {
        const error = await response.json();
        if (error && error.error && error.error.message) {
          const parsedError = JSON.parse(error.error.message);
          setErrorMessages({
            name: parsedError.name ? parsedError.name[0] : '',
            surname: parsedError.surname ? parsedError.surname[0] : '',
            email: parsedError.email ? parsedError.email[0] : '',
            temporary_password: parsedError.temporary_password ? parsedError.temporary_password[0] : '',
            user_type_id: parsedError.user_type_id ? parsedError.user_type_id[0] : ''
          });
        }
        throw new Error('Failed to add user');
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };  

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      {addSuccess ? (
              <p id='change-color' className='fw-bold' >{t('User has been successfully added!')}</p>
            ) : (
        <>
        <h5 id='change-color'>{t('ADD USER')}</h5>
        <Form onSubmit={handleSubmit} className='form-group'>
          <Row className="mb-3">
            <Form.Group controlId="formName" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3} id='change-color'>{t('Name')}</Form.Label>
              <Col sm={9} id='change-color'>
                <Form.Control
                  placeholder={t('Name')}
                  type="text"
                  name="name"
                  value={profileData.name}
                  onChange={handleInputChange}
                  className='rounded-0 placeholder-color'
                  
                />
                {errorMessages.name && <div className="text-danger">{errorMessages.name}</div>}
              </Col>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="formSurname" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3} id='change-color'>{t('Surname')}</Form.Label>
              <Col sm={9} id='change-color'>
                <Form.Control
                  placeholder={t('Surname')}
                  type="text"
                  name="surname"
                  value={profileData.surname}
                  onChange={handleInputChange}
                  className='rounded-0 placeholder-color'
                />
              {errorMessages.surname && <div className="text-danger">{errorMessages.surname}</div>}
              </Col>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="formEmail" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3} id='change-color'>{t('Email')}</Form.Label>
              <Col sm={9} id='change-color'>
                <Form.Control
                  placeholder={t('example@example.com')}
                  type="email"
                  name="email"
                  value={profileData.email}
                  onChange={handleInputChange}
                  className='rounded-0 placeholder-color'
                />
              {errorMessages.email && <div className="text-danger">{errorMessages.email}</div>}
              </Col>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="formPassword" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3} id='change-color'>{t('Temporary Password')}</Form.Label>
              <Col sm={9} id='change-color'>
                <Form.Control
                  placeholder={t('Temporary Password')}
                  type="password"
                  name="temporary_password" 
                  value={profileData.temporary_password}
                  onChange={handleInputChange}
                  className='rounded-0 placeholder-color'
                />
              {errorMessages.temporary_password && <div className="text-danger">{errorMessages.temporary_password}</div>}
              </Col>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="formUserType" as={Col} className="d-flex align-items-center">
              <Form.Label column sm={3} id='change-color'>{t('User Type')}</Form.Label>
              <Col sm={9} id='change-color'>
              <Form.Control
                as="select"
                name="user_type_id"
                value={profileData.user_type_id}
                onChange={handleInputChange}
                className='rounded-0'
              >
                  <option value="" disabled selected id='change-color'>{t('Select User Type')}</option>
                  <option value="1" id='change-color'>Super Admin</option>
                  <option value="2" id='change-color'>Marketing</option>
                </Form.Control>
                {errorMessages.user_type_id && <div className="text-danger">{errorMessages.user_type_id}</div>}
              </Col>
            </Form.Group>
          </Row>
          <Row className='mt-4'>
            <Col sm={12} className='d-flex justify-content-center'>
              <Button  variant="btn btn-primary fs-4 px-5" type="submit" id='login-button' className='rounded-0'>
                {t('Add User')}
              </Button>
              <Button id='change-color' onClick={handleClose} variant="btn px-2 mx-4" className='rounded-0 text-decoration-underline border-0'>
                {t('Cancel')}
              </Button>
            </Col>
          </Row>
        </Form>
        </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
