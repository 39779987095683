import axios from 'axios';
import i18n from 'i18next';
import { BUS_SETTINGS_GET, BUS_SETTINGS_PUT } from './endpoints';

const getCurrentLanguage = () => i18n.language;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  const currentLanguage = getCurrentLanguage();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['Accept-Language'] = currentLanguage; 
  return config;
});

const config = {
  headers: { 
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    'Accept-Language': getCurrentLanguage() 
  }
};

const busSettings = {
    get: (key) => axios.get(BUS_SETTINGS_GET.replace('%(key)s', key), config),

  
    publish: (key, data) => axios.put(BUS_SETTINGS_PUT.replace('%(key)s', key), data, config),
};

export default busSettings;
