const BASE_API_ENDPOINT = "https://api.skpairport.mk/api/v1";

// Taxi
export const DASHBOARD_TAXI_ROUTE_LIST = `${BASE_API_ENDPOINT}/dashboard/taxi/route/list`;
export const DASHBOARD_TAXI_ROUTE_STORE = `${BASE_API_ENDPOINT}/dashboard/taxi/route/store`;
export const DASHBOARD_TAXI_ROUTE_EDIT = `${BASE_API_ENDPOINT}/dashboard/taxi/route/edit/%(id)s`;
export const DASHBOARD_TAXI_ROUTE_DELETE = `${BASE_API_ENDPOINT}/dashboard/taxi/route/delete/%(id)s`;

//Rent-a-car
export const DASHBOARD_RENT_A_CAR_OPERATOR_LIST =`${BASE_API_ENDPOINT}/dashboard/rent-a-car/list`;
export const DASHBOARD_RENT_A_CAR_OPERATOR_DETAILS =`${BASE_API_ENDPOINT}/dashboard/rent-a-car/get/%(id)s`;
export const DASHBOARD_RENT_A_CAR_OPERATOR_STORE =`${BASE_API_ENDPOINT}/dashboard/rent-a-car/store`;
export const DASHBOARD_RENT_A_CAR_OPERATOR_DELETE =`${BASE_API_ENDPOINT}/dashboard/rent-a-car/destroy/%(id)s`;
export const DASHBOARD_RENT_A_CAR_OPERATOR_EDIT =`${BASE_API_ENDPOINT}/dashboard/rent-a-car/edit/%(id)s`;


//Car park 
//Parking
export const DASHBOARD_CAR_PARK_PARKING_LIST =`${BASE_API_ENDPOINT}/dashboard/car-park/parking/list`;
export const DASHBOARD_CAR_PARK_PARKING_STORE =`${BASE_API_ENDPOINT}/dashboard/car-park/parking/store`;
export const DASHBOARD_CAR_PARK_PARKING_DELETE =`${BASE_API_ENDPOINT}/dashboard/car-park/parking/delete/%(id)s`;
export const DASHBOARD_CAR_PARK_PARKING_EDIT =`${BASE_API_ENDPOINT}/dashboard/car-park/parking/edit/%(id)s`;


//Hug&Fly
export const DASHBOARD_CAR_PARK_HUG_AND_FLY_LIST =`${BASE_API_ENDPOINT}/dashboard/car-park/hug-and-fly/list`;
export const DASHBOARD_CAR_PARK_HUG_AND_FLY_STORE =`${BASE_API_ENDPOINT}/dashboard/car-park/hug-and-fly/store`;
export const DASHBOARD_CAR_PARK_HUG_AND_FLY_DELETE =`${BASE_API_ENDPOINT}/dashboard/car-park/hug-and-fly/delete/%(id)s`;
export const DASHBOARD_CAR_PARK_HUG_AND_FLY_EDIT =`${BASE_API_ENDPOINT}/dashboard/car-park/hug-and-fly/edit/%(id)s`;

//Bus
export const BUS_SETTINGS_GET=`${BASE_API_ENDPOINT}/dashboard/hotspot-settings/%(key)s`;
export const BUS_SETTINGS_PUT=`${BASE_API_ENDPOINT}/dashboard/hotspot-settings/edit/%(key)s`;



//Subscription
export const DASHBOARD_CAR_PARK_SUBSCRIPTION_LIST =`${BASE_API_ENDPOINT}/dashboard/car-park/subscription-package/list`;
export const DASHBOARD_CAR_PARK_SUBSCRIPTION_STORE =`${BASE_API_ENDPOINT}/dashboard/car-park/subscription-package/store`;
export const DASHBOARD_CAR_PARK_SUBSCRIPTION_DELETE =`${BASE_API_ENDPOINT}/dashboard/car-park/subscription-package/delete/%(id)s`;
export const DASHBOARD_CAR_PARK_SUBSCRIPTION_EDIT =`${BASE_API_ENDPOINT}/dashboard/car-park/subscription-package/edit/%(id)s`;


//Maps
export const UPLOAD_MAPS_ENDPOINT=`${BASE_API_ENDPOINT}/dashboard/global-map/upload/%(type_name)s`;
export const GLOBAL_MAP_GET = `${BASE_API_ENDPOINT}/global-map/get/type/%(type_name)s`;

//Settings
export const DASHBOARD_SETTINGS_GET_TEXT=`${BASE_API_ENDPOINT}/dashboard/hotspot-settings/%(key)s`;
export const DASHBOARD_SETTINGS_PUBLISH_TEXT=`${BASE_API_ENDPOINT}/dashboard/hotspot-settings/edit/%(key)s`;
