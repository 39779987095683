import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './LoginScreen.css';
import logo_tav from '../../assets/images/logo-tav.png';
import { useTranslation } from 'react-i18next';
import env from '../../assets/env.js'


const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState('');

  const language_text = ['PLEASE SELECT YOUR PREFERRED LANGUAGE', 'ВЕ МОЛИМЕ ИЗБЕРЕТЕ ГО ВАШИОТ ЈАЗИК', 'JU LUTEM ZGJIDHNI GJUHËN TUAJ E PREFERUAR'];
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isLanguageManuallySelected, setIsLanguageManuallySelected] = useState(false);

  const languageOptions = ['en', 'mk', 'al'];
  const { t, i18n } = useTranslation();


  const [emailPlaceholder, setEmailPlaceholder] = useState(t('Email*'));
  const [passwordPlaceholder, setPasswordPlaceholder] = useState(t('Password*'));


  useEffect(() => {
      setEmailPlaceholder(t('Email*'));
      setPasswordPlaceholder(t('Password*'));
  }, [t,emailPlaceholder]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = (password) => {
    return /^(?=.{1,})/.test(password);
  };


  const handleLanguageChange = (language) => {
    const newIndex = languageOptions.indexOf(language);
    if (newIndex !== -1) {
      setCurrentIndex(newIndex);
    }
    i18n.changeLanguage(language);
    setIsLanguageManuallySelected(true);
    navigate(`/${language}/login`);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isEmailValid() && isPasswordValid(password)) {
      // console.log(env.API_URL)
      try {
        const response = await fetch(env.API_URL+'/login', {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': languageOptions[currentIndex],
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (response.ok) { 
          const data = await response.json();
          // console.log(data);
          // console.log(data.data.role.id);
          const { authorization } = data;
          const { access_token } = authorization;
  
          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('userRole', data.data.role.id);
          localStorage.setItem('email', email);

          setEmail('');
          setPassword('');
  
          navigate(`/${languageOptions[currentIndex]}/index`, { replace: true });
        } else {
          const error = await response.json();
          setErrMsg(error.error.message);
          setEmail('');
          setPassword('');
          throw new Error('Login Failed');
        }
      } catch (err) {
        if (!err.response) {
          console.log('Login Failed');
        } 
      }
    }
  };

  const getButtonVariant = (languageIndex) => {
    return currentIndex === languageIndex ? "outline-primary fw-bold opacity-100" : "outline-primary";
  };
  
  useEffect(() => {
    if(errMsg){
      setErrMsg(t('Invalid login data.'));
    }
  }, [t, errMsg]);


  useEffect(() => {
    if (!isLanguageManuallySelected) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % language_text.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [language_text.length, isLanguageManuallySelected]);

  return (
    <div className="login-screen">
      <div className="container-fluid">
        <div className="row">
            <div className='col-xl-12 text-center'>
                <div className="center-logo">
                  <Link to={`/${i18n.language}/login`}>
                    <img src={logo_tav} alt="Your Logo" />
                  </Link>
                </div>
            </div>
        </div>
          <div id='row-parrent' className='row'>
          <div className="col-sm-6 mt-3">
            <div className="language-chooser">
              <span className='mb-3' id='selection-text-normal'>{language_text[currentIndex]}</span>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(0)} // 0 for English
                onClick={() => handleLanguageChange('en')}
              >
                ENGLISH
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(1)} // 1 for Macedonian
                onClick={() => handleLanguageChange('mk')}
              >
                MACEDONIAN
              </Button>
              <Button
                id='button-language-styler'
                variant={getButtonVariant(2)} // 2 for Albanian
                onClick={() => handleLanguageChange('al')}
              >
                ALBANIAN
              </Button>
            </div>
          </div>
          <div className="col-sm-6 vertical-line">
          <form id='form-styling' className=' mt-3'>
            <span id='login-txt'>{t('LOG IN')}</span>
            <div className="form-group mt-4">
              <input 
                placeholder={emailPlaceholder}
                type="text" 
                className="form-control rounded-0" 
                id="email" 
                onChange={handleEmailChange}
                value={email}
                required
              />
            </div>
            <div className="form-group mb-3">
              <input 
                placeholder={passwordPlaceholder}
                type="password" 
                className="form-control rounded-0" 
                id="password" 
                onChange={handlePasswordChange}
                value={password}
                required
              />
             {errMsg && <p className="error-message text-danger">{errMsg}</p>}
            </div>
              <button 
              onClick={handleSubmit} 
              id='login-button' 
              type="submit" 
              className="btn btn-primary rounded-0 w-100 fs-3 "
              disabled={!isEmailValid() || !isPasswordValid(password)}
              >
                {t('NEXT')}
              </button>
              <Link  id='change-color'  to={`/${i18n.language}/forgot-password`} className="forgot-password opacity-50">{t('Forgot your password?')}</Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
